
const errors = [
  { error: 'pay_in_code', message: 'Pay-In-Code'},
  { error: 'Depot not found', message: 'Pay-In-Code'},
  { error: 'from', message: 'sender'},
  { error: 'to', message: 'recipient'},
  { error: 'Authorization', type: 'auth'},
  { error: 'jwt expired', type: 'auth'},
  { error: 'invalid token', type: 'auth'}
]

export const SESSION_EXPIRED = 'Session expired, logging out.'
export default function formatErrorMessage (error) {
  const matchedError = errors.find((item) => error.includes(item.error))
  let message = error
  if (matchedError) {
    message = matchedError.type === 'auth'
      ? SESSION_EXPIRED
      : 'Please enter a valid ' + matchedError.message
  }
  return message
}
