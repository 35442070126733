import React from 'react'
import {
  Grid,
  Typography
} from '@material-ui/core'

const GridTitle = ({ text, variant = 'h4', ...props }) => {
  return (
    <Grid item xs={12} {...props}>
      <Typography variant={variant}>
        {text || props.children}
      </Typography>
    </Grid>
  )
}

export default GridTitle
