import React from 'react'
import {
  TextField
} from '@material-ui/core'

const CustomTextField = (props) => {
  return (
    <TextField
      variant='outlined' fullWidth
      {...props}
      size='small'
    />
  )
}

export default CustomTextField
