import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  box: {
    marginTop: '116px',
    paddingLeft: '90px',
    paddingRight: '90px'
  }
})

const ContentGrid = (props) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Box className={classes.box}>
        {props.children}
      </Box>
    </Grid>
  )
}

export default ContentGrid
