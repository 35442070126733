import React, { useState } from 'react'
import SearchView from './SearchView'
import useUserSearch from './useUserSearch'

const Users = (props) => {
  const [query, setQuery] = useState('')
  const { users, loading } = useUserSearch(query)

  return (
    <SearchView onSearch={setQuery} searching={loading} query={query} userData={users} />
  )
}

export default Users
