import React, { useState } from 'react'
import ReactMde from 'react-mde'
import ReactMarkdown from 'react-markdown'
import './styles.css'
import 'react-mde/lib/styles/css/react-mde-all.css'

const Content = ({ text, onChange }) => {
  const [selectedTab, setSelectedTab] = useState('write')

  return (
    <div className='container'>
      <ReactMde
        value={text}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown source={markdown} />)}
        childProps={{ writeButton: { tabIndex: -1 } }}
        minEditorHeight={650}
      />
    </div>
  )
}

export default Content
