import React, { useEffect, useState, useCallback } from 'react'
import useFetch from 'use-http'

import List from './List'
import { Box, Typography } from '@material-ui/core'
import { GradientButton } from '../common/Buttons'

import NewToken from './NewToken'

const Tokens = () => {
  const { get, post, delete: destroy } = useFetch('/user/tokens', { cachePolicy: 'no-cache' })

  const [tokens, setTokens] = useState([])
  const [pairingError, setPairingError] = useState('')
  const [showNewToken, setShowAddNewToken] = useState(false)

  const refreshTokenList = useCallback(async () => {
    const { tokens } = await get()
    await setTokens(tokens)
  }, [get])

  const handlePairing = useCallback(async ({ otpSecret, otpCode, name }) => {
    await setPairingError()
    const { error } = await post({ name, secret: otpSecret, key: otpCode })
    await setPairingError(error)
    await refreshTokenList()
    await hideNewTokenDialog()
  }, [post, refreshTokenList])

  const handleDelete = useCallback(async ({ id }) => {
    await destroy(id)
    await refreshTokenList()
  }, [destroy, refreshTokenList])

  useEffect(() => {
    refreshTokenList()
  }, [refreshTokenList])

  const showNewTokenDialog = () => setShowAddNewToken(true)
  const hideNewTokenDialog = () => setShowAddNewToken(false)

  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent='space-between' m={1}>
        <Typography variant='h4'>Tokens</Typography>
        <Box pb='30px' display='flex' flexDirection='row'>
          <GradientButton variant='contained' color='primary' onClick={showNewTokenDialog} id='new-token'>New Token</GradientButton>
        </Box>
      </Box>

      <List data={tokens} onDelete={handleDelete} />

      {showNewToken && <NewToken onClose={hideNewTokenDialog} onPair={handlePairing} error={pairingError} />}
    </>
  )
}

export default Tokens
