import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import FormGrid from '../common/FormGrid'
import GridItem from '../common/GridItem'
import PaymentButton from '../common/Buttons/PaymentButton'
import CancelButton from '../common/Buttons/CancelButton'
import AsyncAutocomplete from '../common/AsyncAutocomplete'

import { useApi } from '../common/Api'
import { useHistory } from 'react-router-dom'

const Add = () => {
  const [userInput, setUserInput] = useState('')
  const { Users } = useApi()
  const history = useHistory()
  const defaultUserId = ''
  const [userId, setUserId] = useState(defaultUserId)
  const [addAvaible, setAddAvaible] = useState(false)

  const returnToAdmin = () => {
    history.push('/admins')
  }
  const handleSubmit = async () => {
    const res = await Users.promote(userId)
    if (res) {
      returnToAdmin()
    }
  }
  const handleOnChange = (event, value) => {
    if (value && value.id) {
      setUserId(value.id)
      setAddAvaible(true)
    }
  }

  return (
    <FormGrid
      title='Add Admin'
    >
      <GridItem xs={12}>
        {
          <AsyncAutocomplete
            label='User'
            value={userInput} setValue={setUserInput}
            onChange={handleOnChange}
            fetchFunction={Users.search}
          />
        }
      </GridItem>
      <GridItem xs={12}>
        <Grid container direction='row'>
          <GridItem xs={6}>
            <CancelButton onClick={returnToAdmin} variant='contained'>
              Cancel
            </CancelButton>
          </GridItem>
          <GridItem xs={6} container justify='flex-end'>
            <PaymentButton onClick={handleSubmit} disabled={!addAvaible}>
              Add
            </PaymentButton>
          </GridItem>
        </Grid>
      </GridItem>
    </FormGrid>
  )
}

export default Add
