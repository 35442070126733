import React from 'react'
import { Tab, Tabs } from '@material-ui/core'

const contentTypes = [
  { id: 'tos', label: 'Terms of Service' },
  { id: 'data-privacy', label: 'Data Privacy Policy' },
  { id: 'imprint', label: 'Imprint' }
]

const TypeSelect = ({ id, onChange }) => {
  const handleChange = (event, index) => {
    onChange(contentTypes[index].id)
  }

  return (
    <Tabs indicatorColor='primary' value={contentTypes.findIndex(type => type.id === id)} onChange={handleChange}>
      {contentTypes.map((type, index) => <Tab key={index} {...type} />)}
    </Tabs>
  )
}

export default TypeSelect
