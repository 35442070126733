import React, { useState } from 'react'
import ConfirmationDialog from '../common/ConfirmationDialog'
import TextField from '../common/TextField'

const OtpInput = ({ onSubmit, error, onClose }) => {
  const [otp, setOtp] = useState('')
  const handleSubmit = () => onSubmit(otp)

  return (
    <ConfirmationDialog title='Enter OTP Code' onConfirm={handleSubmit} onClose={onClose}>
      <TextField
        value={otp}
        onChange={event => setOtp(event.target.value)}
        onKeyPress={event => event.key === 'Enter' ? handleSubmit() : null}
        error={error}
        helperText={error}
      />
    </ConfirmationDialog>
  )
}

export default OtpInput
