import React, { useState, useEffect, useCallback } from 'react'
import { Typography } from '@material-ui/core'
import Spacer from '../common/Spacer'

import { PaymentButton } from '../common/Buttons'
import FormGrid from '../common/FormGrid'
import GridItem from '../common/GridItem'
import GridTitle from '../common/GridTitle'
import TextField from '../common/TextField'
import AsyncAutocomplete from '../common/AsyncAutocomplete'
import Alert from '../common/Alert'
import DepotSelect from './DepotSelect'
import config from '../../config'
import OtpInput from '../Tokens/OtpInput'

const ManualTransaction = ({ defaultExchangeRate, fetchFunction, handleTransaction }) => {
  const { mkiToMicroMki } = config

  const defaultTransaction = {
    from: '',
    to: '',
    micro_mki: 0,
    comment: ''
  }
  const [error, setError] = useState()
  const [transaction, setTransaction] = useState(defaultTransaction)
  const [otpDialog, setOtpDialog] = useState(false)
  const updateTransaction = useCallback((field, value) => {
    setTransaction({ ...transaction, [field]: value })
  }, [transaction])

  const handleComplete = async (key) => {
    const response = await handleTransaction({ ...transaction, key })

    if (response?.transaction) {
      setTransaction(defaultTransaction)
      setSender(null)
      setRecipient(null)
      setSuccess(true)
      setOtpDialog(false)
    }
    if (response?.error) {
      await setError(response.error)
    }
  }

  const startOtp = async () => {
    setOtpDialog(true)
  }

  const onCloseOtp = async () => {
    setOtpDialog(false)
    return
  }

  const [rate, setRate] = useState()
  useEffect(() => {
    setRate(defaultExchangeRate)
  }, [defaultExchangeRate])

  const [senderType, setSenderType] = useState('user')
  const [sender, setSender] = useState()
  const [recipient, setRecipient] = useState()
  const [recipientType, setRecipientType] = useState('user')
  const [success, setSuccess] = useState(false)

  const handleChange = (event) => {
    const name = event.target.name
    const types = {
      senderType: setSenderType,
      recipientType: setRecipientType
    }
    types[name](event.target.value)
  }

  useEffect(() => {
    if (senderType !== 'user' && (transaction.from === 'user' || !transaction.from)) {
      updateTransaction('from', senderType)
    }
    if (recipientType !== 'user' && (transaction.to === 'user' || !transaction.to)) {
      updateTransaction('to', recipientType)
    }
  }, [transaction, senderType, recipientType, updateTransaction])

  return (
    <FormGrid title='Manual Transaction'>
      <GridTitle variant='h6'>Sender</GridTitle>

      <GridItem xs={12}>
        <DepotSelect type={senderType} handleChange={handleChange} name='senderType' />
      </GridItem>

      <GridItem xs={12}>
        {senderType === 'user' && <AsyncAutocomplete
          label='Sender'
          value={sender} setValue={setSender}
          onChange={(e, value) => {
            if (value) {
              updateTransaction('from', value.id)
            }
          }}
          fetchFunction={fetchFunction}
                                  />}
      </GridItem>
      <GridTitle variant='h6'>Recipient</GridTitle>
      <GridItem xs={12}>
        <DepotSelect type={recipientType} handleChange={handleChange} name='recipientType' />
      </GridItem>
      <GridItem xs={12}>
        {recipientType === 'user' && <AsyncAutocomplete
          label='Recipient'
          value={recipient} setValue={setRecipient}
          onChange={(e, value) => {
            if (value) {
              updateTransaction('to', value.id)
            }
          }}
          fetchFunction={fetchFunction}
                                     />}
      </GridItem>
      <GridTitle variant='h6'>Payment Details</GridTitle>
      <GridItem xs={12}>
        <TextField
          placeholder='0'
          value={transaction.micro_mki / mkiToMicroMki || ''}
          onChange={(e) => updateTransaction('micro_mki', e.target.value * mkiToMicroMki)}
          label='Transfer amount in g'
          type='number'
          InputProps={{
            inputProps: { min: 0, step: 1 }
          }}
        />
      </GridItem>

      <GridItem container justify='space-between'>
        <Typography display='inline'>Equivalent to about: </Typography>
        <Typography display='inline'>{transaction.micro_mki / mkiToMicroMki * rate} EUR</Typography>
      </GridItem>
      <GridItem xs={12}>
        <TextField label='Comment / Transaction ID' onChange={(e) => updateTransaction('comment', e.target.value)} />
      </GridItem>
      <Spacer />

      <GridItem xs={12} container justify='flex-end'>
        <PaymentButton onClick={startOtp}>Perform transaction</PaymentButton>
      </GridItem>

      <Alert handleClose={() => setSuccess(false)}>{success && 'Transaction successful'}</Alert>
      {otpDialog && <OtpInput onSubmit={handleComplete} error={error} onClose={onCloseOtp} />}
    </FormGrid>
  )
}

export default ManualTransaction
