import React from 'react'
import ManualDetails from './ManualTransaction'
import { useApi } from '../common/Api'

const Transaction = (props) => {
  const { Transactions, Depot, defaultExchangeRate } = useApi()

  return (
    <ManualDetails handleTransaction={Transactions.complete} defaultExchangeRate={defaultExchangeRate} fetchFunction={Depot.search} />
  )
}

export default Transaction
