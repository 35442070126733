import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core'
import CancelButton from './Buttons/CancelButton'
import { makeStyles } from '@material-ui/core/styles'
import { PaymentButton } from './Buttons'
const useStyles = makeStyles({
  dialog: {
  },
  title: {
    padding: '60px 150px 0px'
  },
  content: {
    padding: '30px 150px 0px'
  },
  actions: {
    justifyContent: 'space-between',
    padding: '40px 165px 60px 150px'
  }
})

const FormDialog = ({ title = '', onSubmit, submitText = 'Submit', submitDisabled = false, onClose, ...props }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    onClose()
    setOpen(false)
  }
  return (
    <Dialog className={classes.dialog} open={open} onClose={handleClose} fullWidth maxWidth='lg' scroll='body'>
      <DialogTitle className={classes.title}><Typography variant='h4'>{title}</Typography></DialogTitle>
      <DialogContent className={classes.content}>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CancelButton onClick={handleClose} variant='contained'>
          Cancel
        </CancelButton>
        <PaymentButton onClick={onSubmit} color='secondary' disabled={submitDisabled}>
          {submitText}
        </PaymentButton>
      </DialogActions>
    </Dialog>
  )
}

export default FormDialog
