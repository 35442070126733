import React from 'react'
import {
  InputAdornment
} from '@material-ui/core'
import TextField from '../TextField'

const PercentageField = (props) => {
  return (
    <TextField
      label='Transaction fee' type='number'
      {...props}
      InputProps={{
        inputProps: { min: 0, max: 10, step: 0.5 },
        startAdornment: <InputAdornment position='start'>%</InputAdornment>
      }}
    />
  )
}

export default PercentageField
