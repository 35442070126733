import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useApi } from '../common/Api'

export const PayInContext = React.createContext({})
export const usePayIn = () => useContext(PayInContext)

export const PayInProvider = ({ children }) => {
  const { PayIns, setSuccessMessage, defaultExchangeRate, defaultFees } = useApi()
  const [payIns, setPayIns] = useState([])
  const [selectedPayIn, setSelectedPayIn] = useState(null)
  const [defaultFee, setDefaultFee] = useState(0)

  const resetSelectedPayIn = () => setSelectedPayIn(null)

  const getPayIns = useCallback(async () => {
    const payIns = await PayIns.findAll()
    setPayIns(payIns)
  }, [setPayIns, PayIns])

  const completePayIn = async ({ payInCode, comment, microMki, fee, euroCent, iban, exchangeRate, key }) => {
    const response = await PayIns.complete({
      pay_in_code: payInCode,
      micro_mki: microMki,
      euro_cent: euroCent,
      exchange_rate: exchangeRate,
      fee,
      comment,
      iban,
      key
    })

    if (response?.transaction) {
      setSuccessMessage('Pay-In successful')
    }
    return response
  }

  useEffect(() => {
    if (!selectedPayIn) {
      getPayIns()
    }
  }, [getPayIns, selectedPayIn])

  useEffect(() => {
    if (defaultFees) {
      setDefaultFee(defaultFees.payin)
    }
  }, [defaultFees])

  return (
    <PayInContext.Provider
      value={{
        payIns,
        selectedPayIn,
        setSelectedPayIn,
        resetSelectedPayIn,
        completePayIn,
        defaultExchangeRate,
        defaultFee
      }}
    >
      {children}
    </PayInContext.Provider>
  )
}
