import React, { useState, useEffect, useCallback } from 'react'
import Table from '../common/Table'
import TransactionType from './TransactionType'
import Icon from '../common/Icon'

import config from '../../config'
import { formatDateTime } from '../../utils/time'

const TransactionList = ({ data, query = '' }) => {
  const { mkiToMicroMki, euroToCent, locale } = config
  const emptyValue = '-'
  const columns = [
    {
      title: 'Typ',
      field: 'type',
      render: (rowData) => {
        return <TransactionType type={rowData.type} />
      }
    },
    {
      title: 'Date/Time',
      field: 'sentAt',
      render: (rowData) => formatDateTime(rowData.sentAt),
      customFilterAndSearch: (term, rowData) => {
        const date = new Date(rowData.sentAt)
        const options = { weekday: 'long', month: 'long' }
        const dateStrings = date.toLocaleString(locale, options)
        const dateDigits = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, '')
        const termDigits = term.replace(/[^0-9]/g, '')
        return dateStrings.toLowerCase().includes(term.toLowerCase()) || (termDigits.length > 0 && dateDigits.includes(termDigits))
      }
    },
    { title: 'Sender', field: 'sender' },
    { title: 'Recipient', field: 'recipient' },
    { title: 'Gold in g', field: 'mki', type: 'numeric' },
    { title: 'EUR', field: 'eur', type: 'currency', currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 }, emptyValue },
    { title: 'Exchange Rate EUR/g', field: 'exchangeRate', type: 'numeric', headerStyle: { whiteSpace: 'nowrap' }, emptyValue },
    { title: 'Fee', field: 'fee', render: (rowData) => !rowData.fee ? emptyValue : rowData.fee / mkiToMicroMki },
    { title: 'Comment', field: 'comment', render: (rowData) => rowData.comment || rowData?.payIn?.comment || rowData?.payOut?.comment || emptyValue, emptyValue }
  ]
  const formatData = useCallback((data = []) => {
    const depotTypes = {
      fee: 'Fee',
      reserve: 'Reserve',
      clearing: 'Clearing'
    }
    return data.map((item) => {
      let sender = '-'
      if (item.fromDepot && item.fromDepot.user) {
        sender = item.fromDepot.user.email || item.fromDepot.user.phone
      } else if (item.fromDepot) {
        sender = depotTypes[item.fromDepot.type]
      }
      let recipient = emptyValue
      if (item.toDepot && item.toDepot.user && item.toDepot.user.name) {
        recipient = item.toDepot.user.email || item.toDepot.user.phone
      } else if (item.toDepot) {
        recipient = depotTypes[item.toDepot.type]
      }
      const mki = item.microMki / mkiToMicroMki
      let eur
      let exchangeRate

      switch (item.type) {
        case 'payin':
          eur = item.payIn ? item.payIn.euroCent / euroToCent : 0
          exchangeRate = item.payIn ? item.payIn.exchangeRate : 0
          break
        case 'payout':
          if (item.payOut && !item.payOut.pending) {
            eur = item.payOut.euroCent / euroToCent || 0
            exchangeRate = item.payOut.exchangeRate || 0
          }
          break
        default:
      }
      return {
        ...item, sender, recipient, mki, eur, exchangeRate
      }
    })
  }, [euroToCent, mkiToMicroMki])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    const formatted = formatData(data)
    const filtered = formatted.filter((item) => JSON.stringify(item).replace(/("\w+":)/g, '').toLowerCase().indexOf(query.toLowerCase()) !== -1)
    setFilteredData(filtered)
  }, [query, data, formatData])

  const DEBUG = false

  return (
    <Table
      title='Globale Transaktionsübersicht'
      columns={columns}
      data={filteredData}
      actions={DEBUG ? [
        {
          icon: () => <Icon name='arrowForward' />,
          tooltip: 'Log transaction',
          onClick: (event, rowData) => console.log(rowData)
        }
      ] : []}
      options={{
        filtering: true,
        search: false,
        actionsColumnIndex: -1,
        searchFieldStyle: {
          width: 355
        }
      }}
      components={{
        Toolbar: props => (
          <div />
        )
      }}
    />)
}

export default TransactionList
