import { useState, useEffect } from 'react'
import SessionStore from './SessionStore'
import config from '../../../config'
import jwtDecode from 'jwt-decode'

const PubSub = {
  subscribers: [],
  subscribe: function (func) {
    this.subscribers.push(func)
    return func
  },
  unsubscribe: function (subscriberFunc) {
    this.subscribers = this.subscribers.filter(elem => elem !== subscriberFunc)
  },
  publish: function () {
    let data = Session().get()
    try {
      data = JSON.parse(data)
    } catch (e) {}
    this.subscribers.forEach(func => func(data))
  }
}

const Session = () => {
  const sessionStore = SessionStore(config.prefix)
  return {
    start: (params) => {
      sessionStore.set(params.token)
      PubSub.publish()
      return true
    },
    append: (params) => {
      if (!params.headers) {
        params.headers = {
          'Content-Type': 'application/json'
        }
      }
      const session = sessionStore.get()

      if (session) {
        params.headers.Authorization = 'Token ' + session
      }
      return params
    },
    exists: () => {
      const session = sessionStore.get()
      if (session) {
        return true
      } else {
        return false
      }
    },
    get: sessionStore.get,
    destroy: sessionStore.destroy
  }
}

export const useSession = () => {
  const SessionLib = Session()

  const [session, setSession] = useState(SessionLib.get())
  const [decodedSession, setDecodedSession] = useState()

  useEffect(() => {
    if (session) {
      try {
        var decoded = jwtDecode(session)
        setDecodedSession(decoded)
      } catch (error) {
        console.log('jwtDecode(session) error: ', error)
      }
    } else {
      setDecodedSession(false)
    }
  }, [session])

  useEffect(() => {
    const subscriberId = PubSub.subscribe(data => {
      setSession(data)
    })
    return () => {
      PubSub.unsubscribe(subscriberId)
    }
  }, [])

  const publishSessionState = session => {
    PubSub.publish()
    setSession(session)
  }

  const start = params => {
    const returnValue = SessionLib.start(params)
    publishSessionState(params.session)
    return returnValue
  }
  const destroy = () => {
    const returnValue = SessionLib.destroy()
    publishSessionState(null)
    return returnValue
  }

  return {
    session,
    decodedSession,
    startSession: start,
    appendSession: SessionLib.append,
    destroySession: destroy
  }
}

export default Session
