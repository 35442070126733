import React from 'react'
import {
  Box,
  Typography
} from '@material-ui/core'
import PayOutList from './PayOutList'
import PayOutDetails from './PayOutDetails'
import { PayOutProvider } from './usePayout'

const PayOut = (props) => {
  return (
    <PayOutProvider>
      <Box m={1}>
        <Typography variant='h4'>Pay-Out</Typography>
      </Box>
      <PayOutList />
      <PayOutDetails />
    </PayOutProvider>
  )
}

export default PayOut
