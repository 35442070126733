import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

const LanguageSelect = ({ language, onChange }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl variant='outlined' size='small' className={classes.formControl}>
      <InputLabel id='language-label'>
        Language
      </InputLabel>
      <Select
        labelId='language-label'
        id='language'
        value={language}
        onChange={handleChange}
        label='Language'
        size='small'
      >
        <MenuItem value='de'> German
        </MenuItem>
        <MenuItem value='en'> English
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default LanguageSelect
