import React, { useState } from 'react'
import SearchView from './SearchView'
import { AdminSearchProvider } from './useAdminSearch'
import { Box, Typography } from '@material-ui/core'
import { GradientButton } from '../common/Buttons'
import { useHistory } from 'react-router-dom'

const Admins = (props) => {
  const [query, setQuery] = useState('')
  const history = useHistory()

  const handleOnClick = () => {
    history.push('/admins/add')
  }

  return (
    <>
      <AdminSearchProvider query={query}>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='h4'>Administrators</Typography>
          <Box pb='30px' display='flex' flexDirection='row'>
            <GradientButton variant='contained' color='primary' onClick={handleOnClick} id='add-admin'>Add Admin</GradientButton>
          </Box>
        </Box>
        <SearchView onSearch={setQuery} query={query} />
      </AdminSearchProvider>
    </>
  )
}

export default Admins
