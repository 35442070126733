import React from 'react'
import {
  IconButton,
  InputAdornment
} from '@material-ui/core'
import TextField from './CustomTextField'
import Icon from '../Icon'
import copy from 'copy-to-clipboard'

const CopyField = ({ value, ...props }) => {
  return (
    <TextField
      value={value} {...props}
      InputProps={{
        endAdornment:
  <InputAdornment position='end'>
    <IconButton onClick={() => copy(value)} edge='end'>
      <Icon name='fileCopy' />
    </IconButton>
  </InputAdornment>
      }}
    />
  )
}

export default CopyField
