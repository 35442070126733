import Api from './ApiHandler'

const api = Api()

const PayOut = (props, handleError) => {
  return {
    ...props,
    complete: async (params) => {
      const res = await api.exec('/payouts',
        {
          method: 'PUT',
          body: JSON.stringify(params)
        })
      if (res.error) {
        handleError(res.error)
        return
      }
      return res
    }
  }
}

const PayOuts = (handleError) => {
  return {
    findAll: async (params) => {
      const res = await api.exec('/payouts', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        handleError(res.error)
        return
      }
      if (res.transactions) {
        return res.transactions.map(payOut => PayOut(payOut, handleError))
      }
    },
    findOne: async (id) => {
      const res = await api.exec(`/campaigns/${encodeURIComponent(id)}`, {
        method: 'GET'
      })
      if (res.error) {
        throw new Error(res.error)
      }
      return PayOut(res.payOut)
    }
  }
}

export { PayOut }
export default PayOuts
