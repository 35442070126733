import * as React from 'react'

function Logo (props) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox='0 0 40 20' {...props}>
      <path
        d='M10.595 0l5.536 15.91h.082L21.913 0h5.128v9.549l.668-.814c.444-.536.887-1.063 1.33-1.58l.661-.766L35.087 0h3.708l-.356.42a1738.95 1738.95 0 01-5.607 6.581L40 7v2h-8.532l2.054 3H40v2h-5.108L39 20h-3.777l-6.259-9.056-1.923 1.573V20h-3.655V9.83c.004-.953.03-2.155.075-3.606.047-1.502.088-2.446.123-2.834l.006-.066h-.109L17.495 20h-2.877L8.836 3.297h-.109l.054.923c.102 1.831.16 3.575.174 5.232l.004.822V20H6v-6H0v-2h6V9H0V7h6V0h4.595z'
        fill='#FFF'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default Logo
