import React from 'react'
import PropTypes from 'prop-types'

import AddBox from '@material-ui/icons/AddBox'
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined'
import AllInbox from '@material-ui/icons/AllInbox'
import AllInboxOutlined from '@material-ui/icons/AllInboxOutlined'
import ArrowForward from '@material-ui/icons/ArrowForward'
import CallMade from '@material-ui/icons/CallMade'
import CallReceived from '@material-ui/icons/CallReceived'
import ContactMailOutlined from '@material-ui/icons/ContactMailOutlined'
import Administrator from '@material-ui/icons/AssignmentInd'
import Clear from '@material-ui/icons/Clear'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import ExitToApp from '@material-ui/icons/ExitToApp'
import FastRewind from '@material-ui/icons/FastRewind'
import FilterList from '@material-ui/icons/FilterList'
import FileCopy from '@material-ui/icons/FileCopy'
import Gavel from '@material-ui/icons/Gavel'
import List from '@material-ui/icons/List'
import ListAlt from '@material-ui/icons/ListAlt'
import Redo from '@material-ui/icons/Redo'
import Search from '@material-ui/icons/Search'
import SwapCalls from '@material-ui/icons/SwapCalls'
import VpnKey from '@material-ui/icons/VpnKey'
import Warning from '@material-ui/icons/Warning'
import Settings from '@material-ui/icons/Settings'

import { PayIn, PayOut, Send, Fee } from './svgs'

const Icons = {
  AddBox,
  AddBoxOutlined,
  Administrator,
  AllInbox,
  AllInboxOutlined,
  ArrowForward,
  CallMade,
  CallReceived,
  ContactMailOutlined,
  Clear,
  Delete,
  Edit,
  ExitToApp,
  FastRewind,
  FilterList,
  FileCopy,
  Gavel,
  List,
  ListAlt,
  Redo,
  Search,
  SwapCalls,
  VpnKey,
  Warning,
  PayIn,
  PayOut,
  Send,
  Fee,
  Settings
}

const Icon = ({ name, size, color, outlined, style, ...restProps }) => {
  restProps.style = {
    color,
    ...restProps.style
  }
  const componentName = `${name.slice(0, 1).toUpperCase()}${name.slice(1)}${outlined ? 'Outlined' : ''}`
  if (!Icons[componentName]) {
    console.log(`undefined icon with name: ${name}`)
    return <Warning width={size} height={size} color='red' {...restProps} />
  }
  return React.createElement(Icons[componentName], {
    ...restProps,
    color: (color.includes('#') || color.includes('(') || !color) ? 'primary' : color,
    style: { fontSize: size, ...style }
  })
}

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  outlined: PropTypes.bool,
  color: PropTypes.string
}
Icon.defaultProps = {
  size: 28,
  name: 'addBox',
  outlined: false,
  color: ''
}

export default Icon
