import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'

import Editor from './Editor'
import TypeSelect from './TypeSelect'
import LanguageSelect from './LanguageSelect'
import { GradientButton } from '../common/Buttons'
import Alert from '../common/Alert'
import Spacer from '../common/Spacer'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { formatDateTime } from '../../utils/time'

const Contents = ({ id, language, error, success, text, version, onSave, onChange, onVersion }) => {
  const [changeId, setChangeId] = useState(id)
  const [changeLanguage, setChangeLanguage] = useState(language)
  const [didEdit, setDidEdit] = useState(false)
  const [discardChangesDialog, setDiscardChangesDialog] = useState(false)
  const [confirmChange, setConfirmChange] = useState(false)

  const checkIfEdited = () => {
    if (didEdit && !discardChangesDialog) {
      setDiscardChangesDialog(true)
    }
  }
  const handleChangeId = (newId) => {
    checkIfEdited()
    setChangeId(newId)
  }

  const handleChangeLanguage = (newLanguage) => {
    checkIfEdited()
    setChangeLanguage(newLanguage)
  }

  const changeEditedDocument = (changeId !== id || changeLanguage !== language) && (confirmChange || !didEdit)
  if (changeEditedDocument) {
    const redirectUrl = `/contents/${changeId || id || ''}/${changeLanguage || language || ''}`
    return <Redirect to={redirectUrl} />
  }

  const handleChange = (text) => {
    onChange(text)
    if (!didEdit) {
      setDidEdit(true)
    }
  }

  const handleConfirm = () => {
    setConfirmChange(true)
    setDiscardChangesDialog(false)
    setDidEdit(false)
    setConfirmChange(false)
  }

  const handleSave = () => {
    onSave()
    setDidEdit(false)
  }

  const handleVersion = async () => {
    if (didEdit) {
      await onSave()
    }
    await onVersion()
    setDidEdit(false)
  }
  return (
    <>
      {discardChangesDialog &&
        <ConfirmationDialog
          title='Unsaved Changes'
          description='Are your sure you want to continue? Your changes will be lost.'
          onClose={() => setDiscardChangesDialog(false)}
          onConfirm={handleConfirm}
        />}
      <Box display='flex' flexDirection='row' justifyContent='space-between' m={1}>
        <Typography variant='h4'>Content</Typography>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between' m={1}>
        <TypeSelect id={id} onChange={handleChangeId} />
        <Box display='flex' flexDirection='row' alignItems='center'>
          {!!version && <Typography style={{ marginRight: '1rem' }}>{onVersion ? 'Last Published' : 'Last Saved'}: {formatDateTime(new Date(version))}</Typography>}
          <LanguageSelect language={language} onChange={handleChangeLanguage} />
        </Box>
      </Box>
      {(error || success) && <Alert severity={success ? 'success' : 'error'}>{error || success}</Alert>}

      <Editor text={error ? '' : text} onChange={handleChange} />
      <Box display='flex' flexDirection='row'>
        <Spacer flex />
        {!!onVersion && <GradientButton variant='contained' color='primary' onClick={handleVersion} id='version-content' style={{ width: 216, marginRight: 20 }}>Publish new Version</GradientButton>}
        <GradientButton style={{ width: 119, marginRight: 10 }} variant='contained' color='primary' onClick={handleSave} disabled={!didEdit} id='save-content'>Save</GradientButton>
      </Box>
    </>
  )
}

export default Contents
