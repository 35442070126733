import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import InfoCard from '../common/InfoCard'
import Icon from '../common/Icon'
import Spacer from '../common/Spacer'
import { useApi } from '../common/Api/useApi'

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Content = styled.div`
  height: 40px;
  min-width: 484px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Divider = styled.div`
  height: 50px;
  display: inline;
  width: 2px;
  background-color: white;
  opacity: 0.5;
  margin: 0px 20px;
  @media (max-width: 1427px) {
    display: none;
  }
`

const ExchangeRateCard = ({ showSettings }) => {
  const { exchangeRates = {} } = useApi()

  return (
    <InfoCard isDark>
      <CardContainer>
        <InfoContainer>
          <Content>
            <Icon name='callReceived' color='secondary' />
            <Spacer />
            <Typography variant='h5' item>Pay-In Exchange Rate</Typography>
            <Spacer />
            <Typography variant='h5' item>1 g = {exchangeRates.payin} EUR</Typography>
          </Content>

          <Divider />

          <Content>
            <Icon name='callMade' color='error' />
            <Spacer />
            <Typography variant='h5' item>Pay-Out Exchange Rate</Typography>
            <Spacer />
            <Typography variant='h5' item>1 g = {exchangeRates.payout} EUR</Typography>
            <Spacer />
          </Content>

        </InfoContainer>
        <Icon
          name='Settings'
          color='white'
          size='38px'
          style={{ cursor: 'pointer' }}
          onClick={showSettings}
        />
      </CardContainer>
    </InfoCard>
  )
}

export default ExchangeRateCard
