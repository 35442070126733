import React from 'react'
import { Button } from '@material-ui/core'
import { styled, withTheme } from '@material-ui/core/styles'

const StyledButton = styled(withTheme(Button))(props => ({
  marginTop: 10,
  background: props.theme.palette.secondary.background
}))

const PaymentButton = (props) => (
  <StyledButton color='secondary' variant='contained' {...props}>
    {props.children}
  </StyledButton>)

export default PaymentButton
