import config from '../config'
const { mkiToMicroMki, euroToCent } = config

export const convertEuroCentToEuro = (euroCent) => {
  const euro = Number(euroCent) / euroToCent
  return euro
}

export const convertEuroToEuroCent = (euro) => {
  euro = Math.trunc(Number(euro) * 100) / 100
  const euroCent = Number(euro) * euroToCent
  return euroCent
}

export const convertMicroMkiToMKI = (microMki) => {
  return Number(microMki) / mkiToMicroMki
}

export const convertMkiToMicroMki = (mki) => {
  return Number(mki) * mkiToMicroMki
}

export const castToNumber = (value) => value ? Number(value) : 0
