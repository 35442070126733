import React, { useState, useEffect, useContext, useCallback } from 'react'

import { useApi } from '../common/Api'
export const PayOutContext = React.createContext({})
export const usePayout = () => useContext(PayOutContext)

export const PayOutProvider = ({ children }) => {
  const { PayOut, defaultExchangeRate, defaultFees, setSuccessMessage } = useApi()
  const [exchangeRate, setExchangeRate] = useState(defaultExchangeRate)
  const [defaultFee, setDefaultFee] = useState(0)
  useEffect(() => {
    if (defaultFees) {
      setDefaultFee(defaultFees.payout)
    }
  }, [defaultFees])
  const [payOuts, setPayOuts] = useState([])
  const [selectedPayout, setSelectedPayout] = useState(null)
  const resetSelectedPayout = () => setSelectedPayout(null)
  const getPayouts = useCallback(async () => {
    const response = await PayOut.findAll()
    setPayOuts(response)
  }, [setPayOuts, PayOut])

  useEffect(() => {
    if (!selectedPayout) {
      getPayouts()
    }
  }, [getPayouts, selectedPayout])

  const handlePayout = async ({ euroCent, fee, exchangeRate, key, comment }) => {
    const id = selectedPayout.id
    const payOutBody = { id, euro_cent: euroCent, fee, exchange_rate: exchangeRate, key, comment }
    const response = await selectedPayout.complete(payOutBody)
    if (response?.transaction) {
      setSuccessMessage('Pay-Out successful')
    }
    return response
  }

  return (
    <PayOutContext.Provider
      value={{
        payOuts,
        selectedPayout,
        setSelectedPayout,
        resetSelectedPayout,
        handlePayout,
        exchangeRate,
        setExchangeRate,
        defaultFee,
        defaultExchangeRate
      }}
    >
      {children}
    </PayOutContext.Provider>
  )
}
