import React from 'react'
import {
  Box,
  Typography
} from '@material-ui/core'
import PayInList from './PayInList'
import PayInDetails from './PayInDetails'
import { PayInProvider } from './usePayIn'

const PayIn = () => {
  return (
    <PayInProvider>
      <Box display='flex' flexDirection='row' justifyContent='space-between' m={1}>
        <Typography variant='h4'>Pay-In</Typography>
      </Box>
      <PayInList />
      <PayInDetails />
    </PayInProvider>
  )
}

export default PayIn
