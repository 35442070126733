import React, { useState, useContext, useCallback, useEffect } from 'react'

import PayIns from './PayIns'
import PayOut from './PayOut'
import Transactions from './Transaction'
import Depot from './Depot'
import Options from './Options'
import Users from './Users'

import Alert from '../Alert'
import formatErrorMessage from './errors'
export const ApiContext = React.createContext({})
export const useApi = () => useContext(ApiContext)

export const ApiProvider = ({ session, destroySession, children }) => {
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [exchangeRates, setExchangeRates] = useState()
  const [defaultFees, setDefaultFees] = useState()

  const handleError = useCallback((error) => {
    const authErrors = [
      'jwt expired',
      'invalid token',
      'Authorization'
    ]
    if (authErrors.some(authError => authError === error) && destroySession) {
      console.log('logging out due to', error)
      destroySession()
      window.location.reload(false)
    }
    const formattedMessage = formatErrorMessage(error)
    setMessage(formattedMessage)
  }, [setMessage, destroySession])

  const getExchangeRates = useCallback(async () => {
    const exchangeRates = await Options(handleError).getExchangeRates()
    setExchangeRates(exchangeRates)
  }, [setExchangeRates, handleError])

  const updateExchangeRates = useCallback(async (exchangeRates) => {
    try {
      const updatedExchangeRate = await Options().setExchangeRates(exchangeRates)
      setExchangeRates(updatedExchangeRate)
    } catch (error) {
      console.error(error)
    }
  }, [setExchangeRates])

  const getFees = useCallback(async () => {
    const defaultFees = await Options(handleError).getDefaultFees()
    setDefaultFees(defaultFees)
  }, [setDefaultFees, handleError])

  const setSuccessMessage = (message) => {
    setSuccess(true)
    setMessage(message)
  }

  useEffect(() => {
    if (session) {
      getFees()
      getExchangeRates()
    }
  }, [session, getFees, getExchangeRates])

  return (
    <ApiContext.Provider
      value={{
        PayIns: PayIns(handleError),
        PayOut: PayOut(handleError),
        Transactions: Transactions(handleError),
        Depot: Depot(handleError),
        Users: Users(handleError),
        Options: Options(handleError),
        exchangeRates,
        updateExchangeRates,
        defaultFees,
        setSuccessMessage
      }}
    >
      <Alert
        severity={success ? 'success' : 'error'}
        handleClose={() => {
          setMessage('')
          setTimeout(() => setSuccess(false), 1000)
        }}
      >
        {message}
      </Alert>
      {children}
    </ApiContext.Provider>
  )
}
