import styled from 'styled-components'

const ActiveIndicator = styled.div`
  background: linear-gradient(316deg, #D9BB63 0%, #C2A758 100%);
  position: absolute;
  left: 0px;
  top: 0px;
  height: 70px;
  width: 4px;
`

export default ActiveIndicator
