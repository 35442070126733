import { useState, useEffect } from 'react'
import qrcode from 'qrcode'
import { authenticator } from '@otplib/preset-default'
import config from '../../config'

export default ({ user }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState()
  const [otpSecret, setSecret] = useState()
  const [otpCode, setOtpCode] = useState()
  const [otpError, setOtpError] = useState()

  useEffect(() => {
    const secret = authenticator.generateSecret() // base32 encoded hex secret key
    setSecret(secret)
    const otpauth = authenticator.keyuri(user, config.service, secret)
    qrcode.toDataURL(otpauth, (err, imageUrl) => {
      if (err) {
        setOtpError('Error with QR')
        console.log('Error with QR', err)
        return
      }
      setQrCodeUrl(imageUrl)
    })
  }, [user])

  return { otpSecret, otpCode, setOtpCode, qrCodeUrl, otpError }
}
