import React, { } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider as HttpProvider } from 'use-http'
import styled, { ThemeProvider } from 'styled-components'

import Login from './components/Login'
import Users from './components/Users'
import Admins from './components/Admins'
import Transactions from './components/Transactions'
import PayIn from './components/PayIn'
import PayOut from './components/PayOut'
import ManualTransaction from './components/ManualTransaction'
import Contents from './components/Contents'
import Tokens from './components/Tokens'
import AdminAdd from './components/Admins/Add'

import Drawer from './components/common/Drawer'
import ContentGrid from './components/common/ContentGrid'

import history from './utils/history'
import theme from './theme'

import { useSession } from './components/common/Api/Session'
import { ApiProvider } from './components/common/Api/useApi'
import {
  CssBaseline,
  Grid
} from '@material-ui/core'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import config from './config'
import 'typeface-roboto'
import './App.css'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/de'

import Api from './components/common/Api'
import LoginOtp from './components/Login/Otp'


const api = Api()

const BACKEND_URL = process.env.REACT_APP_BACKEND || 'https://moneyki-gold-backend-preview.sol.hpm.agency'

moment.locale(config.locale || 'de')

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { session } = useSession()
  return (
    <Route
      {...rest} render={(props) => (
        session ? <Component {...props} /> : <Redirect to='/login' />
      )}
    />
  )
}

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const muiTheme = React.useMemo(
    () =>
      createMuiTheme(theme(prefersDarkMode)),
    [prefersDarkMode]
  )
  const { session, decodedSession, destroySession } = useSession()

  const logout = async () => {
    await api.logout()
    await destroySession()
  }

  if (decodedSession && decodedSession.requireOtp) {
    return  (
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={muiTheme}>
          <HttpProvider
            url={BACKEND_URL} options={{
              headers: {
                Authorization: `Token ${session}`
              }
            }}
          >
            <LoginOtp onClose={logout}/>
          </HttpProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    )
  }

  return (
    <HttpProvider
      url={BACKEND_URL} options={{
        headers: {
          Authorization: `Token ${session}`
        },
        cachePolicy: 'no-cache'
      }}
    >
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale='de'>
            <ApiProvider session={session} destroySession={destroySession}>
              <Router history={history}>
                <StyledApp>
                  {session && <Drawer onLogout={logout} />}
                  <FixedGrid container spacing={3}>
                    <ContentGrid>
                      <CssBaseline />
                      <Switch>
                        <PrivateRoute path='/transactions' exact component={Transactions} />
                        <PrivateRoute path='/pay-in' exact component={PayIn} />
                        <PrivateRoute path='/pay-out' exact component={PayOut} />
                        <PrivateRoute path='/manual' exact component={ManualTransaction} />
                        <PrivateRoute path='/users' exact component={Users} />
                        <PrivateRoute path='/admins' exact component={Admins} />
                        <PrivateRoute path='/admins/add' exact component={AdminAdd} />
                        <PrivateRoute path='/contents/:id?/:language?' exact component={Contents} />
                        <PrivateRoute path='/tokens' exact component={Tokens} />
                        {(!session || decodedSession?.requireOtp) && <Route path='/login' exact component={Login} />}
                        {(session && decodedSession && !decodedSession.requireOtp) && <Route render={() => <Redirect to='/transactions' />} />}
                        <Route render={() => <Redirect to='/login' />} />
                      </Switch>
                    </ContentGrid>
                  </FixedGrid>
                </StyledApp>
              </Router>
            </ApiProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </HttpProvider>
  )
}

const StyledApp = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FixedGrid = styled(Grid)`
  flex: 1;
  justify-content: flex-start;
  max-width: 1740px;
  flexGrow: 1;
`

export default App
