export default (prefersDarkMode) => ({
  palette: {
    type: prefersDarkMode ? 'dark' : 'light',
    primary: {
      main: '#C29F39',
      contrastText: '#FFF',
      gradient: 'linear-gradient(316deg, #D9BB63 0%, #C2A758 100%)',
      background: '#1F1F1F'
    },
    secondary: {
      main: '#29a0d7',
      contrastText: '#FFF',
      gradient: 'linear-gradient(316deg, #D9BB63 0%, #C2A758 100%)',
      background: '#008B9B'

    },
    error: {
      main: '#D32F2F'
    }
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        color: '#FFF',
        '&:hover': {
          color: '#CCC'
        },
        '&$active': {
          color: '#FFF'
        }
      }
    }
  }
})
