import React from 'react'
import {
  Box,
  Grid,
  Paper
} from '@material-ui/core'
import GridTitle from './GridTitle'
const FormGrid = ({ title, ...props }) => {
  return (
    <Box mx={16}>
      <Paper>
        <Box py={6}>
          <form>
            <Grid container spacing={1} justify='center'>
              <Grid
                container item xs={10}
                justify='center'
                alignItems='center'
                spacing={3}
              >
                {title && <GridTitle text={title} />}
                {props.children}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  )
}

export default FormGrid
