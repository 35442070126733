import moment from 'moment'
import config from '../config'

export const formatDateTime = (date) => {
  const { dateFormat, timeFormat } = config
  return moment(date).format(dateFormat + ' ' + timeFormat)
}

export const formatDate = (date) => {
  const { dateFormat } = config
  return moment(date).format(dateFormat)
}
