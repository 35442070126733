import { useApi } from '../common/Api'
import React, { useState, useEffect, useContext, useCallback } from 'react'

const AdminSearchContext = React.createContext({})
export const useAdminSearch = () => useContext(AdminSearchContext)

export const AdminSearchProvider = ({ query = '', children }) => {
  const type = 'admin'
  const { Users } = useApi()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  const refreshAdminList = useCallback(async () => {
    const res = await Users.search(query, type)
    setLoading(false)
    if (res.error) {
      return
    }
    if (res.httpStatus === 200 && Array.isArray(res.users)) {
      return setUsers(res.users)
    }
  }, [Users, query, type])

  useEffect(() => {
    refreshAdminList()
  }, [query, refreshAdminList])

  return (
    <AdminSearchContext.Provider
      value={{ users, loading, refreshAdminList }}
    >
      {children}
    </AdminSearchContext.Provider>
  )
}
