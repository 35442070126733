import React, { useState } from 'react'
import {
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import styled from 'styled-components'
import Spacer from '../common/Spacer'
import Version from '../common/Version'
import { GradientButton } from '../common/Buttons'
import Logo from '../../logo.js'

import Api from '../common/Api'
const api = Api()

const GridItem = (props) => (
  <Grid item xs={props.xs} {...props}>
    {props.children}
  </Grid>)

const Login = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()
  const authorize = async (e) => {
    e.preventDefault()
    const res = await api.authorize(email, password)
    if (res.error) {
      setError(true)
    }
  }

  return (
    <StyledGrid
      container
      spacing={3}
      justify='center'
      alignItems='center'
    >
      <GridItem>
        <LogoCircle>
          <FixedLogo />
        </LogoCircle>
        <Spacer height={20} />
        <Typography style={{ textAlign: 'center' }} variant='h5' noWrap>Enter Login-Data</Typography>
        <Spacer height={30} />
        <form onSubmit={e => authorize(e)}>
          <TextField
            fullWidth
            id='admin-input'
            label='Email'
            type='email'
            autoComplete='username'
            variant='outlined'
            error={error}
            helperText={error ? '' : ''}
            onChange={(e) => {
              setEmail(e.target.value)
              if (error) {
                setError(false)
              }
            }}
          />
          <Spacer />
          <TextField
            fullWidth
            id='password-input'
            label='Password'
            type='password'
            autoComplete='current-password'
            variant='outlined'
            error={error}
            helperText={error ? 'Invalid login' : ''}
            onChange={(e) => {
              setPassword(e.target.value)
              if (error) {
                setError(false)
              }
            }}
          />
        </form>
        <Spacer height={20} />
        <GradientButton fullWidth variant='contained' color='primary' onClick={authorize} id='login'>
          Anmelden
        </GradientButton>
        <Spacer height={30} />
        <Typography style={{ textAlign: 'center', color: 'rgba(0,0,0,0.54)', fontSize: '14px', fontWeight: '500' }} variant='subtitle1' noWrap>
          Moneyki © https://Moneyki.de 2020
          <Version />
        </Typography>
      </GridItem>
    </StyledGrid>
  )
}

const LogoCircle = styled.div`
  border-radius: 30px;
  height: 60px;
  width: 60px;
  background: linear-gradient(316deg, #D9BB63 0%, #C2A758 100%);
  margin: auto;
  position: relative;
`
const FixedLogo = styled(Logo)`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const StyledGrid = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 0%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`

export default Login
