import React from 'react'
import {
  CircularProgress
} from '@material-ui/core'

const CircularProgressLoading = (props) => {
  return <CircularProgress />
}

export default CircularProgressLoading
