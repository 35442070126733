import React from 'react'
import {
  Box,
  Typography
} from '@material-ui/core'

import Icon from '../common/Icon'
import Spacer from '../common/Spacer'

const types = {
  payin: {
    icon: 'callReceived',
    title: 'Pay-In',
    color: 'secondary'
  },
  payout: {
    icon: 'callMade',
    title: 'Pay-Out',
    color: 'error'
  },
  fee: {
    icon: 'fee',
    title: 'Fee',
    color: '#D9BB63'
  },
  user: {
    icon: 'swapCalls',
    title: 'Internal',
    color: 'rgba(0,0,0,0.26)'
  },
  manual: {
    icon: 'redo',
    title: 'Manual',
    color: 'rgba(0,0,0,0.26)'
  }
}

const TransactionType = ({ type }) => {
  const typeAttributes = types[type] || types.user
  return (
    <Box display='flex'>
      <Icon name={typeAttributes.icon} color={typeAttributes.color} />
      <Spacer width={10} />
      <Typography>{typeAttributes.title}</Typography>
    </Box>)
}

export default TransactionType
