import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography
} from '@material-ui/core'

import GridItem from '../common/GridItem'
import { PercentageField, GoldRateField } from '../common/Payments'
import { CopyField } from '../common/TextField'

import { calculatePayOut } from '../common/Payments/calculate'
import { convertEuroCentToEuro, convertMicroMkiToMKI, castToNumber } from '../../utils/convert'

const PayOutCalculator = ({ microMki = 0, defaultFee = 2, defaultExchangeRate = 50, onChange }) => {
  const [fee, setFee] = useState(defaultFee)
  const [exchangeRate, setExchangeRate] = useState(defaultExchangeRate)
  const [euroCent, setEuroCent] = useState(calculatePayOut({ microMki, exchangeRate, fee }))

  const updateFee = (event) => {
    const value = event.target.value
    if (value >= 0 && value <= 100) {
      setFee(value)
    }
  }

  useEffect(() => {
    setEuroCent(calculatePayOut({ microMki, exchangeRate, fee }))
  }, [microMki, fee, exchangeRate])

  useEffect(() => {
    onChange({
      euroCent: castToNumber(euroCent),
      fee: castToNumber(fee),
      exchangeRate: castToNumber(exchangeRate)
    })
  }, [onChange, euroCent, fee, exchangeRate])

  return (
    <Grid container direction='column' spacing={2}>
      <GridItem container justify='space-between'>
        <Typography display='inline'>Pay-out amount: </Typography>
        <Typography display='inline'>{convertMicroMkiToMKI(microMki).toFixed(3)} g</Typography>
      </GridItem>
      <GridItem>
        <GoldRateField value={exchangeRate} onChange={event => setExchangeRate(event.target.value)} />
      </GridItem>
      <GridItem>
        <PercentageField value={fee} onChange={updateFee} />
      </GridItem>
      <GridItem>
        <CopyField
          value={convertEuroCentToEuro(euroCent).toFixed(2)}
          label='Pay-out in EUR'
        />
      </GridItem>
    </Grid>
  )
}

export default PayOutCalculator
