import React from 'react'
import {
  Grid
} from '@material-ui/core'

const PaymentGrid = (props) => {
  return (
    <Grid container item xs={12} direction='column' alignItems='stretch' spacing={2}>
      {props.children}
    </Grid>
  )
}

export default PaymentGrid
