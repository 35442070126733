import React, { useState } from 'react'
import Table from '../common/Table'
import Icon from '../common/Icon'
import FormDialog from '../common/FormDialog'
import { Grid, Typography } from '@material-ui/core'
import GridTitle from '../common/GridTitle'
import GridItem from '../common/GridItem'
import { useApi } from '../common/Api'
import { useAdminSearch } from './useAdminSearch'
import Spacer from '../common/Spacer'

const List = ({ query = '' }) => {
  const { Users } = useApi()
  const { users, refreshAdminList } = useAdminSearch(query)
  const [selectedAdmin, setSelectedAdmin] = useState('')

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Verified', field: 'verified', render: (rowData) => rowData.verified ? 'Yes' : 'No' }
  ]

  const handleOnClick = (event, rowData) => {
    setSelectedAdmin(rowData)
  }

  const handleDelete = async () => {
    await Users.demote(selectedAdmin.id)
    refreshAdminList()
    onClose()
  }

  const handleAction = [
    {
      icon: () => <Icon name='delete' color='error' />,
      tooltip: 'Delete Admin',
      onClick: handleOnClick
    }
  ]

  const onClose = () => {
    setSelectedAdmin('')
  }

  return (
    <>
      <Table
        title='Admins'
        columns={columns}
        data={users}
        actions={handleAction}
        options={{
          filtering: true,
          search: false,
          actionsColumnIndex: -1,
          searchFieldStyle: {
            width: 355
          }
        }}
        components={{
          Toolbar: props => (
            <div />
          )
        }}
      />
      {selectedAdmin &&
        <FormDialog
          submitText='Delete'
          onSubmit={handleDelete}
          onClose={onClose}
        >
          <Grid container>
            <GridTitle container>
              Delete Admin
            </GridTitle>
            <GridItem xs={12}>
              <Spacer height={20} />
            </GridItem>
            <GridItem>
              <Typography> Are you sure you want to delete {selectedAdmin.name} ?</Typography>
            </GridItem>
          </Grid>
        </FormDialog>}
    </>
  )
}

export default List
