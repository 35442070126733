import React from 'react'
import { Button } from '@material-ui/core'
import { styled, withTheme } from '@material-ui/core/styles'

const StyledButton = styled(withTheme(Button))(props => ({
  background: props.destructive ? props.theme.palette.error.main : props.theme.palette.primary.gradient
}))

const DestructiveButton = (props) => (
  <StyledButton variant='contained' {...props}>
    {props.children}
  </StyledButton>)

export default DestructiveButton
