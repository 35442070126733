import React from 'react'
import PropTypes from 'prop-types'
import {
  ListItem as _ListItem,
  ListItemIcon as _ListItemIcon,
  withStyles
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import Icon from '../Icon'
import ActiveIndicator from './ActiveIndicator'
const ListItem = withStyles({
  root: {
    padding: '0',
    width: '70px',
    height: '70px',
    '&.Mui-selected, &.Mui-selected:hover, &:hover': {
      backgroundColor: 'rgba(238,238,238,0.1)'
    }
  }
})(_ListItem)

const ListItemIcon = withStyles({
  root: {
    marginLeft: '22px'
  }
})(_ListItemIcon)

const DrawerItem = ({ icon, selected, ...props }) => {
  return (
    <ListItem
      button {...props}
      component={Link}
      selected={selected}
    >
      {selected &&
        <ActiveIndicator />}
      <ListItemIcon>
        <Icon name={icon} color={selected ? '#D2B55F' : '#FFF'} />
      </ListItemIcon>
    </ListItem>
  )
}

DrawerItem.propTypes = {
  icon: PropTypes.string,
  selected: PropTypes.bool
}
DrawerItem.defaultProps = {
  icon: 'warning',
  selected: false
}

export default DrawerItem
