import React from 'react'
import { styled, withTheme, makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Button,
  IconButton,
  Toolbar as _Toolbar,
  Typography
} from '@material-ui/core'
import Logo from '../../../logo'
import Icon from '../Icon'
import Spacer from '../Spacer'

const useStyles = makeStyles(theme => ({
  spacer: {
    flexGrow: 1
  },
  button: {
    borderRadius: '50%',
    width: '48px',
    minWidth: '48px',
    height: '48px',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  }
}))

const NavBar = ({ title, version, onLogout, ...restProps }) => {
  const classes = useStyles()

  return (
    <AppBar {...restProps}>
      <Toolbar>
        <IconButton>
          <Logo />
        </IconButton>
        <Spacer width={20} />
        <Typography variant='h6' noWrap>{title}</Typography>
        <div className={classes.spacer} />
        <Button className={classes.button} href='#/' disableElevation onClick={onLogout}>
          <Icon name='exitToApp' color='primary' />
        </Button>
        {version && <Typography variant='caption'>v{version}</Typography>}
      </Toolbar>
    </AppBar>
  )
}

const Toolbar = styled(withTheme(_Toolbar))(props => ({
  background: props.theme.palette.primary.gradient
}))

export default NavBar
