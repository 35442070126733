import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
const DepotSelect = ({ type, handleChange, name }) => {
  return (
    <FormControl variant='outlined' fullWidth size='small'>
      <InputLabel>Depot</InputLabel>
      <Select
        value={type}
        onChange={handleChange}
        label='Age'
        inputProps={{ name }}
      >
        <MenuItem value='user'>User</MenuItem>
        <MenuItem value='fee'>Fee</MenuItem>
        <MenuItem value='clearing'>Clearing</MenuItem>
        <MenuItem value='reserve'>Reserve</MenuItem>
      </Select>
    </FormControl>
  )
}

export default DepotSelect
