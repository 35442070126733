import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'

import {
  Snackbar
} from '@material-ui/core'

const Alert = ({ handleClose, anchorOrigin, ...props }) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center', ...anchorOrigin }}
      open={!!props.children}
      onClose={handleClose}
    >
      <MuiAlert elevation={6} variant='filled' {...props} />
    </Snackbar>)
}

export default Alert
