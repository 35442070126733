import React, { useState, useEffect } from 'react'
import Spacer from '../common/Spacer'
import PayInCalculator from './PayInCalculator'
import GridItem from '../common/GridItem'
import GridTitle from '../common/GridTitle'
import TextField from '../common/TextField'
import FormDialog from '../common/FormDialog'
import { usePayIn } from './usePayIn'
import OtpInput from '../Tokens/OtpInput'

const PayInDetails = () => {
  const { selectedPayIn, resetSelectedPayIn, completePayIn, defaultFee } = usePayIn()
  const [payInCode, setPayInCode] = useState('')
  const [comment, setComment] = useState('')
  const [iban, setIban] = useState('')

  const [euroCent, setEuroCent] = useState(0)
  const [fee, setFee] = useState(0)
  const [exchangeRate, setExchangeRate] = useState(0)
  const [microMki, setMicroMki] = useState(0)
  const [otpDialog, setOtpDialog] = useState(false)
  const [error, setError] = useState()

  const onChangeCalculation = ({ euroCent, microMki, fee, exchangeRate }) => {
    setEuroCent(euroCent)
    setMicroMki(microMki)
    setFee(fee)
    setExchangeRate(exchangeRate)
  }

  const onSubmit = async (key) => {
    try {
      await completePayIn({ payInCode, comment, microMki, fee, euroCent, iban, exchangeRate, key })
      resetSelectedPayIn()
      await setOtpDialog(false)
    } catch (error) {
      await setError(error)
    }
  }

  useEffect(() => {
    if (selectedPayIn) {
      setPayInCode(selectedPayIn.payInCode)
      setComment(selectedPayIn.comment)
      setIban(selectedPayIn.iban)

      setExchangeRate(selectedPayIn.exchangeRate)
      setEuroCent(selectedPayIn.euroCent)
      setMicroMki(selectedPayIn.microMki)
    }
  }, [selectedPayIn])

  const startOtp = async () => {
    setOtpDialog(true)
  }

  const onCloseOtp = async () => {
    setOtpDialog(false)
  }

  if (!selectedPayIn) return <></>

  return (
    <FormDialog
      title='Pay-In'
      submitText='Confirm Pay-In'
      onSubmit={startOtp}
      onClose={resetSelectedPayIn}
    >

      <GridTitle variant='h6'>Recipient</GridTitle>
      <GridItem container item xs={12} direction='column' alignItems='stretch' spacing={2}>
        <GridItem>
          <TextField
            label='Pay-In-Code'
            value={payInCode}
            onChange={event => setPayInCode(event.target.value)}
          />
        </GridItem>
      </GridItem>
      <Spacer />

      <GridTitle variant='h6'>Payment Details</GridTitle>
      <PayInCalculator
        defaultEuroCent={euroCent}
        defaultExchangeRate={exchangeRate}
        defaultFee={defaultFee}
        onChange={onChangeCalculation}
      />
      <GridItem container spacing={2}>
        <GridItem xs={12}>
          <TextField label='Comment / Transaction ID' value={comment} onChange={event => setComment(event.target.value)} />
        </GridItem>
        <GridItem>
          <TextField label='IBAN' value={iban} onChange={event => setIban(event.target.value)} />
        </GridItem>
      </GridItem>
      {otpDialog && <OtpInput onSubmit={onSubmit} error={error} onClose={onCloseOtp} />}
    </FormDialog>
  )
}

export default PayInDetails
