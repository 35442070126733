import React from 'react'

import TextField from '../TextField'

const PercentageField = (props) => {
  return (
    <TextField
      label='Gold rate EUR/g'
      type='number'
      {...props}
      InputProps={{
        inputProps: { step: 0.01 }
      }}
    />
  )
}

export default PercentageField
