export default {
  backend: process.env.REACT_APP_BACKEND || 'https://moneyki-gold-backend-preview.sol.hpm.agency',
  prefix: 'sol',
  title: 'Moneyki Gold',
  mkiToMicroMki: 10000,
  euroToCent: 100,
  locale: 'en-US',
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'HH:mm',
  service: 'MoneyKi'
}
