import React from 'react'
import {
  Typography
} from '@material-ui/core'
import Table from '../common/Table'
import Icon from '../common/Icon'

import { usePayout } from './usePayout'
import config from '../../config'
import { formatDateTime } from '../../utils/time'

const PayOutList = () => {
  const { payOuts, setSelectedPayout } = usePayout()
  const { mkiToMicroMki } = config

  const columns = [
    { title: 'Date/Time', field: 'sentAt', type: 'datetime', render: (rowData) => formatDateTime(rowData.sentAt) },
    { title: 'Recipient', field: 'recipient' },
    { title: 'E-Mail', field: 'contact' },
    { title: 'IBAN', field: 'payOut.iban' },
    {
      title: 'Amount',
      field: 'amount',
      type: 'numeric',
      render: (rowData) => {
        return <Typography>{rowData.amount} g</Typography>
      }
    }
  ]
  
  const formatData = (data = []) => {
    if (!data) return 
    console.log('data', data)
    return data.map((item) => {
      let recipient
      let contact
      if (item.fromDepot && item.fromDepot.user) {
        recipient = item.fromDepot.user.name || item.fromDepot.user.email || '-'
        contact = item.fromDepot.user.email || item.fromDepot.user.phone || '-'
      }
      const amount = item.microMki / mkiToMicroMki

      return {
        ...item, recipient, contact, amount
      }
    })
  }
  return (
    <>
      <Table
        title='Pay Out'
        columns={columns}
        data={formatData(payOuts)}
        actions={[
          {
            icon: () => <Icon name='arrowForward' />,
            tooltip: 'Start Pay-out',
            onClick: (event, rowData) => setSelectedPayout(rowData)
          }
        ]}
        options={{
          actionsColumnIndex: -1
        }}
        components={{
          Toolbar: props => (
            <div />
          )
        }}
      />
    </>
  )
}

export default PayOutList
