import React from 'react'
import SearchField from './SearchField'
import List from './List'
import Spacer from '../common/Spacer'
import CircularProgressLoading from '../common/CircularProgressLoading'
import Box from '@material-ui/core/Box'
import Alert from '../common/Alert.js'

const NoUsersFoundText = 'No users for given search conditions have been found'

export default ({ query, onSearch, userData = [], searching = false, ...props }) => {
  const search = (searchText) => {
    onSearch(searchText)
  }

  return (
    <Box justify='space-between' direction='column' spacing={4}>
      <SearchField defaultValue={query} onSubmit={search} />
      <Spacer />
      {searching && <center><CircularProgressLoading /></center>}
      {query && !searching && !userData.length && <Alert severity='error'>{NoUsersFoundText}</Alert>}

      <List data={userData} />
    </Box>
  )
}
