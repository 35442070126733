import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Typography
} from '@material-ui/core'
import Timeframe from '../common/Timeframe'
import Spacer from '../common/Spacer'
import DepotCard from './DepotCard'
import TransactionList from './TransactionList'
import { SearchField } from '../common/TextField'
import { useApi } from '../common/Api/useApi'
import config from '../../config'
import ExchangeRateCard from './ExchangeRateCard'
import FormChangeExchangeRate from './FormChangeExchangeRate'

const Transactions = () => {
  const { Depot, Transactions, exchangeRates } = useApi()
  const { mkiToMicroMki } = config
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const [depotInfo, setDepotInfo] = useState({
    balanceMicroMKI: 0,
    defaultExchangeRate: 50,
    lastUpdated: new Date().toLocaleString('de')
  })

  const [transactions, setTransactions] = useState([])
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [timeframe, setTimeframe] = useState({})
  const getTimeframe = async (transactions) => {
    const length = transactions ? transactions.length : 0
    if (length > 0) {
      transactions.sort((a, b) => {
        return new Date(b.sentAt) - new Date(a.sentAt)
      })
      const startDate = new Date(transactions[length - 1].sentAt)
      const endDate = new Date(transactions[0].sentAt)
      setTimeframe({ startDate, endDate })
    }
  }

  useEffect(() => {
    const getDepotInfo = async () => {
      try {
        const response = await Depot.get()
        if (response && response.depot) {
          const balanceMicroMKI = response.depot.microMki
          const lastUpdated = new Date().toLocaleString('de')
          const exchangeRate = exchangeRates ? exchangeRates.payin : 0
          setDepotInfo({ balanceMicroMKI, exchangeRate, lastUpdated })
        }
      } catch (err) {
        console.log(err)
      }
    }
    getDepotInfo()

    const getTransactions = async () => {
      const response = await Transactions.findAll()
      setTransactions(response)
      getTimeframe(response)
    }
    getTransactions()
  }, [Depot, Transactions, mkiToMicroMki, exchangeRates])

  useEffect(() => {
    getTimeframe(transactions)
  }, [transactions])

  const filterTransactions = useCallback(() => {
    if (!transactions) return 
    const filtered = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.sentAt)
      const { startDate, endDate } = timeframe
      return transactionDate >= startDate && transactionDate <= endDate
    })
    return filtered
  }, [transactions, timeframe])

  useEffect(() => {
    const filtered = filterTransactions()
    setFilteredTransactions(filtered)
  }, [timeframe, filterTransactions])

  const [searchQuery, setSearchQuery] = useState('')
  return (
    <>
      <ExchangeRateCard showSettings={() => setIsSettingsOpen(true)} />
      <FormChangeExchangeRate close={() => setIsSettingsOpen(false)} isOpen={isSettingsOpen} />
      <DepotCard balanceMicroMKI={depotInfo.balanceMicroMKI} lastUpdated={depotInfo.lastUpdated} exchangeRate={depotInfo.exchangeRate} />
      <Box display='flex' flexDirection='row' justifyContent='space-between' m={1}>
        <Typography variant='h4'>Global Transactions</Typography>
        <Box pb='30px' display='flex' flexDirection='row'>
          <Timeframe timeframe={timeframe} setTimeframe={setTimeframe} />
          <Spacer />
          <SearchField onSubmit={setSearchQuery} />
        </Box>
      </Box>
      <TransactionList data={filteredTransactions} query={searchQuery} />
    </>
  )
}

export default Transactions
