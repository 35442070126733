import React, { useState, useCallback } from 'react'
import Table from '../common/Table'
import Icon from '../common/Icon'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { formatDateTime } from '../../utils/time'

const List = ({ data, onDelete = () => { } }) => {
  const [tokenToDelete, setTokenToDelete] = useState('')

  const handleDelete = useCallback(async () => {
    await onDelete(tokenToDelete)
    await setTokenToDelete('')
  }, [onDelete, tokenToDelete])

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Created At', field: 'createdAt', render: (rowData) => formatDateTime(rowData.createdAt) }
  ]

  return (
    <>
      <Table
        title='Tokens'
        columns={columns}
        data={data}
        actions={[
          {
            icon: () => <Icon name='delete' color='error' />,
            tooltip: 'Remove token',
            onClick: (event, rowData) => setTokenToDelete(rowData)
          }
        ]}
        options={{
          filtering: true,
          search: false,
          actionsColumnIndex: -1,
          searchFieldStyle: {
            width: 355
          }
        }}
        components={{
          Toolbar: props => (
            <div />
          )
        }}
      />
      {!!tokenToDelete && <ConfirmationDialog title={`Delete ${tokenToDelete.name}`} description='Are you sure?' onConfirm={handleDelete} onClose={() => setTokenToDelete(null)} />}

    </>
  )
}

export default List
