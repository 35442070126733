import Api from './ApiHandler'

const api = Api()

const PayIns = (handleError) => {
  return {
    findAll: async (params) => {
      const { error, payIns } = await api.exec('/payins', {
        method: 'GET',
        body: JSON.stringify(params)
      })

      if (error) {
        handleError(error)
        return
      }
      return payIns.filter(payIn => (payIn.pending))
    },
    complete: async function (payIn) {
      const res = await api.exec(`/payins/${payIn.pay_in_code}`, {
        method: 'PUT',
        body: JSON.stringify(payIn)
      })
      if (res.error) {
        handleError(res.error)
        return
      }
      return res
    }
  }
}

export { PayIns }
export default PayIns
