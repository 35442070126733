import Api from './ApiHandler'

const api = Api()

const Transaction = (props) => {
  return {
    ...props,
    revoke: async function () {
      const res = await api.exec('/transaction',
        {
          method: 'PUT',
          body: JSON.stringify(this)
        })
      if (res.error) {
        throw new Error(res.error)
      }
      return Transaction(res)
    }
  }
}

const Transactions = (handleError) => {
  return {
    findAll: async (params) => {
      const res = await api.exec('/alltransactions', {
        method: 'GET',
        body: JSON.stringify(params)
      })
      if (res.error) {
        handleError(res.error)
        return
      }
      if (res.transactions) {
        return res.transactions.map(payOut => Transaction(payOut))
      }
    },
    complete: async function (params) {
      const res = await api.exec('/transactions/manual',
        {
          method: 'POST',
          body: JSON.stringify(params)
        })
      if (res.error) {
        handleError(res.error)
        return
      }
      return res
    }
  }
}

export { Transaction }
export default Transactions
