import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  CircularProgress
} from '@material-ui/core'
import TextField from './TextField'
const AsyncAutocomplete = ({ label, value, setValue, fetchFunction = () => {}, ...props }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const loading = open && options.length === 0

  useEffect(() => {
    let active = true
    if (!loading) {
      return undefined
    }
    (async () => {
      const response = await fetchFunction(value)
      let list = []
      if (response) {
        list = response.users
      }

      if (active && list && list.length > 0) {
        setOptions(list)
      }
    })()

    return () => {
      active = false
    }
  }, [fetchFunction, loading, value])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || option.email}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          label={label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      {...props}
    />
  )
}

export default AsyncAutocomplete
