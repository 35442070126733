import React, { useEffect } from 'react'
import SearchField from './SearchField'
import List from './List'
import Spacer from '../common/Spacer'
import CircularProgressLoading from '../common/CircularProgressLoading'
import Box from '@material-ui/core/Box'
import Alert from '../common/Alert.js'
import { useAdminSearch } from './useAdminSearch'

const NoAdminsFoundText = 'No admins for given search conditions have been found'

export default ({ noRefresh = false, query, onSearch, searching = false, ...props }) => {
  const search = (searchText) => {
    onSearch(searchText)
  }

  const { users, loading, refreshAdminList } = useAdminSearch(query)

  useEffect(() => {
    if (!noRefresh) {
      refreshAdminList()
    }
  }, [refreshAdminList, noRefresh])

  return (
    <Box justify='space-between' direction='column' spacing={4}>
      <SearchField defaultValue={query} onSubmit={search} />
      <Spacer />
      {(loading || searching) && <center><CircularProgressLoading /></center>}
      {query && !loading && (!props.users || (users && !users.length)) && <Alert severity='error'>{NoAdminsFoundText}</Alert>}
      <List prefilledUsers={props.users || users} />
    </Box>
  )
}
