import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import FormDialog from '../common/FormDialog'
import TextField from '../common/TextField'
import Spacer from '../common/Spacer'

import { useApi } from '../common/Api/useApi'

const FormChangeExchangeRate = ({ isOpen, close }) => {
  const { exchangeRates, updateExchangeRates } = useApi()
  const [payInRate, setPayInRate] = useState(0)
  const [payOutRate, setPayOutRate] = useState(0)

  useEffect(() => {
    if (exchangeRates) {
      setPayInRate(exchangeRates.payin)
      setPayOutRate(exchangeRates.payout)
    }
  }, [exchangeRates])

  const submit = async () => {
    const payInNumber = Number(payInRate)
    const payOutNumber = Number(payOutRate)
    await updateExchangeRates({ payin: payInNumber, payout: payOutNumber })
    close()
  }

  const updatePayInRate = (event) => setPayInRate(event.target.value)
  const updatePayOutRate = (event) => setPayOutRate(event.target.value)

  if (!isOpen) {
    return <></>
  }

  return (
    <FormDialog
      title='Gold Exchange Rate'
      submitText='Confirm'
      onSubmit={submit}
      onClose={close}
    >
      <Typography variant='h5' item>Pay-In</Typography>
      <Spacer />
      <TextField
        value={payInRate}
        onChange={updatePayInRate}
        label='Euro'
        type='number'
      />

      <Spacer height='30' />
      <Typography variant='h5' item>Pay-Out</Typography>
      <Spacer />
      <TextField
        value={payOutRate}
        onChange={updatePayOutRate}
        label='Euro'
        type='number'
      />
    </FormDialog>
  )
}

export default FormChangeExchangeRate
