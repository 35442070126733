import { useState, useEffect } from 'react'

export default (url, options) => {
  const [response, setResponse] = useState({})
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true)
      try {
        const res = await window.fetch(url, options)
        const json = await res.json()
        setResponse(json)
      } catch (error) {
        setError(error)
      }
      await setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line
  }, [url])

  return { loading, response, error }
}
