import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card
} from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    minWidth: 275,
    marginBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 40,
    paddingBottom: 40,
    background: ({ isDark }) => isDark ? palette.primary.background : palette.primary.gradient,
    color: palette.primary.contrastText
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}))

const InfoCard = ({ children, isDark }) => {
  const classes = useStyles({ isDark })
  return (
    <Card className={classes.root}>
      {children}
    </Card>
  )
}

export default InfoCard
