import Api from './ApiHandler'

const api = Api()

const Options = (handleError) => {
  return {
    getDefaultFees: async function (type) {
      const { defaultFee, error } = await api.exec('/options/defaultFee', {
        method: 'GET'
      })
      if (error) {
        handleError(error)
        return
      }
      const defaultFees = { ...defaultFee.percentagePerType }
      return defaultFees
    },
    getExchangeRates: async function () {
      const res = await api.exec('/options/exchange-rates',
        {
          method: 'GET'
        })
      if (res.error) {
        handleError(res.error)
        return
      }
      return res.exchangeRates ? res.exchangeRates : res
    },
    setExchangeRates: async function (exchangeRates) {
      const { error } = await api.exec('/options/exchange-rates', {
        method: 'PUT',
        body: JSON.stringify(exchangeRates)
      })
      if (error) {
        throw new Error(error)
      }
      return exchangeRates
    }
  }
}

export default Options
