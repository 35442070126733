import React from 'react'
import { DatePicker } from '@material-ui/pickers'
import Spacer from './Spacer'
import config from '../../config'

const DATE_FORMAT = config.dateFormat || 'YYYY-MM-DD'
const roundDate = (timeStamp, roundUp) => {
  var d = new Date(timeStamp)
  if (roundUp) {
    d.setHours(23, 59, 59, 999)
  } else {
    d.setHours(0, 0, 0, 0)
  }
  return d
}

export default ({ timeframe, setTimeframe }) => {
  const updateStartDate = (startDate) => {
    setTimeframe({ ...timeframe, startDate })
  }
  const updateEndDate = (endDate) => {
    setTimeframe({ ...timeframe, endDate })
  }
  return (
    <>
      <DatePicker
        display='inline'
        autoOk
        disableFuture
        inputVariant='outlined'
        size='small'
        label='Startdate'
        format={DATE_FORMAT}
        value={timeframe.startDate}
        onChange={e => {
          const roundedDown = roundDate(e._d)
          updateStartDate(roundedDown)
        }}
      />
      <Spacer />
      <DatePicker
        display='inline'
        autoOk
        disableFuture
        inputVariant='outlined'
        size='small'
        label='Enddate'
        format={DATE_FORMAT}
        value={timeframe.endDate}
        onChange={e => {
          const roundedUp = roundDate(e._d, true)
          updateEndDate(roundedUp)
        }}
      />
    </>
  )
}
