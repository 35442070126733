import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography
} from '@material-ui/core'
import InfoCard from '../common/InfoCard'
import Spacer from '../common/Spacer'
import { convertMicroMkiToMKI, convertEuroCentToEuro } from '../../utils/convert'

const DepotCard = ({ balanceMicroMKI, exchangeRate, lastUpdated }) => {
  const [balanceEuroCent, setBalanceEuroCent] = useState(0)

  useEffect(() => {
    if (balanceMicroMKI && exchangeRate) {
      const balanceEuroCent = (Number(balanceMicroMKI) * exchangeRate) / 100
      setBalanceEuroCent(balanceEuroCent)
    }
  }, [balanceMicroMKI, exchangeRate])
  return (
    <InfoCard>
      <Typography variant='h4'>Company Depot</Typography>
      <Spacer />
      <Typography variant='caption'>Updated: {lastUpdated}</Typography>
      <Spacer />
      <Grid item container justify='space-between'>
        <Typography variant='h3' display='inline'>{convertMicroMkiToMKI(balanceMicroMKI)} g</Typography>
        <Typography variant='h3' display='inline'>{convertEuroCentToEuro(balanceEuroCent).toFixed(2)} EUR</Typography>
      </Grid>
    </InfoCard>
  )
}

DepotCard.propTypes = {
  balance: PropTypes.number,
  lastUpdated: PropTypes.string
}
DepotCard.defaultProps = {
  balance: 0,
  lastUpdated: new Date().toLocaleString('de')
}

export default DepotCard
