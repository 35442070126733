import React, { useState } from 'react'
import FormDialog from '../common/FormDialog'
import {
  Grid,
  Typography
} from '@material-ui/core'
import PayOutCalculator from './PayOutCalculator'
import TextField, { CopyField } from '../common/TextField'
import GridItem from '../common/GridItem'
import GridTitle from '../common/GridTitle'
import Spacer from '../common/Spacer'

import { usePayout } from './usePayout'
import { formatDateTime } from '../../utils/time'
import OtpInput from '../Tokens/OtpInput'

const PayOutDetails = () => {
  const { selectedPayout, handlePayout, resetSelectedPayout, defaultFee, defaultExchangeRate } = usePayout()
  const [euroCent, setEuroCent] = useState(0)
  const [fee, setFee] = useState(0)
  const [comment, setComment] = useState("")
  const [exchangeRate, setExchangeRate] = useState(defaultExchangeRate)
  const [otpDialog, setOtpDialog] = useState(false)
  const [error, setError] = useState()

  if (!selectedPayout) return <></>
  let recipient
  if (selectedPayout.fromDepot && selectedPayout.fromDepot.user) {
    recipient = selectedPayout.fromDepot.user
  }

  const onSubmit = async (key) => {
    try {
      const success = await handlePayout({ euroCent, fee, exchangeRate, key, comment })
      if (success && success.transaction) {
        resetSelectedPayout()
        await setOtpDialog(false)
      }
    } catch (error) {
      await setError(error)
    }
  }

  const startOtp = async () => {
    setOtpDialog(true)
  }

  const onCloseOtp = async () => {
    setOtpDialog(false)
  }

  const updateCalculationData = ({ euroCent, fee, exchangeRate }) => {
    setEuroCent(euroCent)
    setFee(fee)
    setExchangeRate(exchangeRate)
  }

  return (
    <FormDialog
      title='Pay-Out' submitText='Confirm Payment'
      onSubmit={startOtp} onClose={resetSelectedPayout}
    >
      <Grid container direction='column' alignItems='stretch' spacing={2}>
        <GridItem container justify='space-between'>
          <Typography display='inline'>Date of Order: </Typography>
          <Typography display='inline'>{formatDateTime(selectedPayout.sentAt)}</Typography>
        </GridItem>
        <GridTitle variant='h6'>Recipient</GridTitle>
        <GridItem>
          <CopyField label='Name' value={recipient.name} />
        </GridItem>
        <GridItem>
          <CopyField label='IBAN' value={selectedPayout.payOut.iban} />
        </GridItem>
        <GridItem>
          <CopyField label='Email-address' value={recipient.email || recipient.phone_number} />
        </GridItem>

        <GridTitle variant='h6'>Payment Details</GridTitle>

      </Grid>

      <PayOutCalculator
        microMki={selectedPayout.microMki}
        defaultFee={defaultFee}
        defaultExchangeRate={selectedPayout.payOut.exchangeRate || defaultExchangeRate}
        onChange={updateCalculationData}
      />
      <Spacer />
      <Grid container direction='column' alignItems='stretch' spacing={2}>
        <GridItem>
          <TextField label='Comment / Transaction ID' value={comment} onChange={(event) => setComment(event.target.value)} />
        </GridItem>
      </Grid>
      {otpDialog && <OtpInput onSubmit={onSubmit} error={error} onClose={onCloseOtp} />}
    </FormDialog>
  )
}

export default PayOutDetails
