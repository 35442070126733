import Api from './ApiHandler'

const api = Api()

const Users = (handleError) => {
  return {
    search: async (query = '', type = 'user') => {
      const res = await api.exec('/users/admin',
        {
          method: 'GET',
          body: JSON.stringify({
            query,
            limit: 10000,
            type
          })
        })
      if (res.error) {
        handleError(res.error)
        return res
      }
      return res
    },
    promote: async (id) => {
      const res = await api.exec(`/users/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            type: 'admin'
          })
        })
      if (res.error) {
        handleError(res.error)
        return null
      }
      return res
    },
    demote: async (id) => {
      const res = await api.exec(`/users/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            type: 'user'
          })
        })
      if (res.error) {
        handleError(res.error)
        return null
      }
      return res
    },
    complete: async function (users) {
      const res = await api.exec('/users',
        {
          method: 'POST',
          body: JSON.stringify(users)
        })
      if (res.error) {
        handleError(res.error)
      }
      return res
    }
  }
}

export default Users
