import Request from './Request'
import Session from './Session'
import config from '../../../config'

const ACCEPTED_USER_ROLE = 'admin'

const Api = () => {
  const request = Request()
  return {
    exec: async (url, params, noSession) => {
      const session = Session(config.prefix)
      params = session.append(params)
      const res = await request.exec(`${config.backend}${url}`, params)
      return res
    },
    authorize: async (email, password) => {
      const res = await request.authorize(`${config.backend}/admins/authenticate`, { email, password })
      let userType = 'unknown'

      if (res.token) {
        const payload = parseJwt(res.token)
        userType = payload.type

        if (userType !== ACCEPTED_USER_ROLE) {
          res.error = 'Insufficient permission to proceed'
        }
      }

      if (userType === ACCEPTED_USER_ROLE) {
        Session().start(res)
      }

      return res
    },
    logout: async () => {
      let options = { method: 'DELETE' }
      const session = Session(config.prefix)
      options = session.append(options)

      return request.exec(`${config.backend}/users/session`, options)
    },
    download: async (url, params, noSession) => {
      const res = request.download(`${config.backend}${url}`, params)
      return res
    },
    setConfig: ({ backend, prefix }) => {
      config = { backend, prefix }
    },
    buildUrl: (url) => {
      return `${config.backend}${url}`
    }
  }
}

function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
};

export default Api
