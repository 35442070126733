import React, { useEffect, useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import useFetch from 'use-http'
import View from './View'
import ConfirmationDialog from '../common/ConfirmationDialog'

const TOS_CONTENT_ID = 'tos'

const Contents = ({ match }) => {
  const { id, language } = match.params
  const [text, setText] = useState('')
  const [tosVersion, setTosVersion] = useState()
  const [publishVersion, setPublishVersion] = useState(false)
  const [updatedAt, setUpdatedAt] = useState()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const { get, post } = useFetch('/contents')
  const { post: publishTos } = useFetch('/tos')
  const { get: getCurrentTosVersion } = useFetch('/tos/current')

  const loadContent = useCallback(async () => {
    if (!id || !language) {
      return
    }
    const { error, content = {} } = await get(`/${id}/${language}`)
    await setText(content.text)
    await setUpdatedAt(content.updatedAt)
    await setError(error)
    setSuccess()
  }, [get, id, language])

  useEffect(() => {
    loadContent()
  }, [loadContent])

  const handleSave = useCallback(async () => {
    await setError()
    const { error } = await post(`/${id}/${language}`, { text })
    if (error) {
      await setError(error)
    } else {
      setSuccess('Saved')
    }
  }, [post, id, language, text])

  const handleChange = async (text) => {
    setSuccess()
    await setText(text)
  }

  const handlePublishVersion = useCallback(async () => {
    await setError()
    const { error } = await publishTos()
    if (error) {
      setError(error)
    } else {
      setSuccess('New TOS Version has been published')
    }
    setPublishVersion(false)
  }, [publishTos])

  const updateCurrentTosVersion = useCallback(async () => {
    const { version } = await getCurrentTosVersion()
    await setTosVersion(version)
  }, [getCurrentTosVersion])

  useEffect(() => {
    updateCurrentTosVersion()
  }, [error, success, updateCurrentTosVersion])

  if ((!id || !language) && !error) {
    return (<Redirect to={`contents/${id || 'tos'}/${language || 'en'}`} />)
  }

  return (
    <>
      <View
        id={id}
        language={language}
        text={text}
        error={error}
        success={success}
        version={TOS_CONTENT_ID === id ? tosVersion : updatedAt}
        onVersion={TOS_CONTENT_ID === id && !publishVersion ? () => setPublishVersion(true) : null}
        onSave={handleSave}
        onChange={handleChange}
      />
      {publishVersion &&
        <ConfirmationDialog
          title='Publish Terms of Service'
          description='Are you sure you want to publish a new version of the terms of service?'
          onClose={() => setPublishVersion(false)}
          onConfirm={handlePublishVersion}
        />}
    </>)
}

export default Contents
