import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DestructiveButton } from './Buttons'

const useStyles = makeStyles({
  dialog: {
  },
  title: {
    padding: '60px 150px 0px'
  },
  content: {
    padding: '30px 150px 0px'
  },
  actions: {
    justifyContent: 'space-between',
    padding: '40px 150px 60px 150px'
  }
})

const ConfirmationDialog = ({ title = '', onConfirm, confirmText = 'Confirm', description = '', submitDisabled = false, destructive = false, onClose, ...props }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setOpen(false)
  }
  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      maxWidth='md'
      scroll='body'
    >
      <DialogTitle className={classes.title}><Typography variant='h4'>{title}</Typography></DialogTitle>
      <DialogContent className={classes.content}>
        {description &&
          <DialogContentText>
            {description}
          </DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} variant='contained'>
          Cancel
        </Button>
        <DestructiveButton onClick={onConfirm} autoFocus destructive={destructive}>
          {confirmText}
        </DestructiveButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
