import useFetch from '../common/useFetch'
import { useSession } from '../common/Api/Session'
import config from '../../config'

const BACKEND_URL = config.backend

export default (query = '', sessionToken) => {
  const { session } = useSession()
  const { loading, response: { users = [] } } = useFetch(`${BACKEND_URL}/users/admin?query=${encodeURIComponent(query)}&limit=10000`, {
    headers: {
      Authorization: `Token ${session}`
    }
  })

  return { users, loading }
}
