import React, { useState } from 'react'

import FormDialog from '../common/FormDialog'
import { Grid, Typography } from '@material-ui/core'
import TextField, { CopyField } from '../common/TextField'
import GridItem from '../common/GridItem'
import Spacer from '../common/Spacer'

import useOtp from './useOtp'

const Pairing = ({
  user = '', onClose, onPair = () => {
  }, error
}) => {
  const { otpSecret, otpCode, setOtpCode, qrCodeUrl, otpError } = useOtp({ user })
  const [name, setName] = useState('')
  const handlePairing = () => onPair({ otpCode, otpSecret, name })

  return (
    <FormDialog
      title='Pair new token'
      submitText='Confirm pairing'
      onSubmit={handlePairing}
      submitDisabled={!otpCode || !name}
      onClose={onClose}
    >
      <Grid
        container
        direction='column'
        alignItems='stretch'
        spacing={2}
      >
        <GridItem container justify='space-between'>
          <Typography display='inline'>
            QR Code
          </Typography>
          <img src={qrCodeUrl} alt={otpSecret} />
        </GridItem>
      </Grid>
      <Spacer />
      <Grid
        container
        direction='column'
        alignItems='stretch'
        spacing={2}
      >
        <GridItem>
          <TextField
            required
            label='Token Name'
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </GridItem>
        <Spacer />
        <GridItem>
          <CopyField label='Secret' value={otpSecret} />
          <Spacer />
          <TextField
            label='OTP Code'
            value={otpCode}
            error={error || otpError}
            helperText={error}
            onChange={event => setOtpCode(event.target.value)}
            onKeyPress={event => event.key === 'Enter' && name ? handlePairing() : null}
          />
        </GridItem>
      </Grid>
    </FormDialog>
  )
}

export default Pairing
