import React from 'react'
import Table from '../common/Table'
import config from '../../config'
import { formatDate, formatDateTime } from '../../utils/time'

const List = ({ data, query = '' }) => {
  const { mkiToMicroMki } = config

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Email', field: 'email' },
    { title: 'Depot', field: 'depot.microMki', type: 'numeric', render: (rowData) => rowData.depot.microMki / mkiToMicroMki },
    { title: 'Registration Date', field: 'createdAt', render: (rowData) => formatDateTime(rowData.createdAt) },
    { title: 'Birthday', field: 'birthday', render: (rowData) => rowData.birthday ? formatDate(rowData.birthday) : '–' },
    { title: 'Verified', field: 'verified', render: (rowData) => rowData.verified ? 'Yes' : 'No' }
  ]

  return (
    <Table
      title='Users'
      columns={columns}
      data={data}
      options={{
        filtering: true,
        search: false,
        actionsColumnIndex: -1,
        searchFieldStyle: {
          width: 355
        }
      }}
      components={{
        Toolbar: props => (
          <div />
        )
      }}
    />
  )
}

export default List
