import React, { useState } from 'react'
import {
  IconButton,
  InputAdornment
} from '@material-ui/core'
import TextField from './CustomTextField'
import Icon from '../Icon'

const SearchField = ({ onSubmit, value: defaultValue, inputProps, ...props }) => {
  const [value, setValue] = useState(defaultValue)
  const [clear, setClear] = useState(false)

  const onClick = () => {
    if (clear) {
      onSubmit('')
      setValue('')
      setClear(false)
    } else if (value !== '') {
      onSubmit(value)
      setClear(true)
    }
  }
  return (
    <TextField
      {...props}
      fullWidth={false}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault()
          onSubmit(value)
          setClear(true)
        }
      }}
      InputProps={{
        endAdornment:
  <InputAdornment position='end'>
    <IconButton onClick={onClick} edge='end'>
      <Icon name={clear ? 'clear' : 'search'} style={{ color: 'white' }} />
    </IconButton>
  </InputAdornment>
      }}
    />
  )
}

export default SearchField
