import React from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline, Drawer, List as _List, withStyles } from '@material-ui/core'

import NavBar from './NavBar'
import DrawerItem from './DrawerItem'
import config from '../../../config'

const drawerWidth = 70

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#1f1f1f'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
}))

const List = withStyles({
  root: {
    paddingTop: '30px',
    overflow: 'hidden'
  }
})(_List)

const ClippedDrawer = ({ currentPath, onClick, onLogout }) => {
  const location = useLocation()
  const classes = useStyles()

  const views = [
    { name: 'Transaktionen', route: '/transactions', icon: 'list' },
    { name: 'Pay In', route: '/pay-in', icon: 'payIn' },
    { name: 'Manuelle Transaktion', route: '/manual', icon: 'send' },
    { name: 'Pay Out', route: '/pay-out', icon: 'payOut' },
    { name: 'Users', route: '/users', icon: 'ContactMailOutlined' },
    { name: 'Admins', route: '/admins', icon: 'Administrator' },
    { name: 'Content', route: '/contents', icon: 'Gavel' },
    { name: 'Tokens', route: '/tokens', icon: 'VpnKey' }
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar
        title={config.title}
        position='fixed'
        className={classes.appBar}
        onLogout={onLogout}
      />
      <Drawer className={classes.drawer} variant='permanent' classes={{ paper: classes.drawerPaper }}>
        <div className={classes.toolbar} />
        <List>
          {views.map((view, index) => {
            const isSelected = location.pathname.includes(view.route)
            return (
              <DrawerItem
                key={view.name}
                to={view.route}
                icon={view.icon}
                selected={isSelected}
                onClick={onClick}
              />)
          }
          )}
        </List>
      </Drawer>
    </div>
  )
}

export default ClippedDrawer
