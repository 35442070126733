import { convertEuroCentToEuro, convertMkiToMicroMki } from '../../../utils/convert'

export function getFee (amount, feePercentage) {
  const fraction = feePercentage / 100
  return amount * fraction
}

export function subtractFee (amount, feePercentage) {
  return amount - getFee(amount, feePercentage)
}

export function calculatePayOut ({ microMki, fee, exchangeRate }) {
  let euroCent = subtractFee(microMki, fee) / 100
  euroCent = euroCent * exchangeRate
  return euroCent
}

export function calculatePayIn ({ euroCent, fee, exchangeRate }) {
  const euro = convertEuroCentToEuro(euroCent)
  const totalMki = euro / exchangeRate
  const feeMki = getFee(totalMki, fee)
  const userMki = totalMki - feeMki

  if (isNaN(totalMki) || isNaN(userMki)) return { microMkiUser: 0, microMkiFee: 0, microMkiTotal: 0 }
  return {
    microMkiTotal: convertMkiToMicroMki(totalMki),
    microMkiUser: convertMkiToMicroMki(userMki),
    microMkiFee: convertMkiToMicroMki(feeMki)
  }
}
