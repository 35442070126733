import React from 'react'
import Typography from '@material-ui/core/Typography'
import { version as APPLICATIONVERSION } from '../../../package.json'

const Version = (props) => {
  return (
    <Typography variant='subtitle2' noWrap>
      Version {APPLICATIONVERSION}
    </Typography>
  )
}

export default Version
