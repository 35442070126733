import Api from './ApiHandler'

const api = Api()

const Depot = (handleError) => {
  return {
    get: async function () {
      const res = await api.exec('/depot',
        {
          method: 'GET',
          body: JSON.stringify({ type: 'fee' })
        })
      if (res.error) {
        handleError(res.error)
        return
      }
      return res
    },
    search: async function (query) {
      const res = await api.exec('/users',
        {
          method: 'GET',
          body: JSON.stringify({ query })
        })
      if (res.error) {
        handleError(res.error)
      }
      return res
    }
  }
}

export default Depot
