import React from 'react'
import Table from '../common/Table'
import config from '../../config'
import { formatDateTime } from '../../utils/time'
import Icon from '../common/Icon'
import { usePayIn } from './usePayIn'

const PayInList = () => {
  const { payIns, setSelectedPayIn } = usePayIn()

  const { mkiToMicroMki, euroToCent, locale } = config
  const emptyValue = '-'
  const columns = [
    {
      title: 'Date/Time',
      field: 'createdAt',
      render: (rowData) => formatDateTime(rowData.createdAt),
      customFilterAndSearch: (term, rowData) => {
        const date = new Date(rowData.createdAt)
        const options = { weekday: 'long', month: 'long' }
        const dateStrings = date.toLocaleString(locale, options)
        const dateDigits = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().replace(/[^0-9]/g, '')
        const termDigits = term.replace(/[^0-9]/g, '')
        return dateStrings.toLowerCase().includes(term.toLowerCase()) || (termDigits.length > 0 && dateDigits.includes(termDigits))
      }
    },
    { title: 'Name', field: 'depot.user.name' },
    { title: 'E-Mail', field: 'depot.user.email' },
    {
      title: 'Gold Kurs',
      field: 'exchangeRate',
      type: 'currency',
      currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 },
      emptyValue
    },
    { title: 'Gold in g', field: 'mki', type: 'numeric' },
    { title: 'EUR', field: 'euro', type: 'currency', currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 }, emptyValue },
    { title: 'Pay-In Code', field: 'payInCode', type: 'numeric' }
  ]

  const formatData = (payIns) => {
    return payIns.map((item) => {
      const mki = item.microMki / mkiToMicroMki
      const euro = item.euroCent / euroToCent
      return {
        ...item, mki, euro
      }
    })
  }

  return (
    <Table
      title='Pay In'
      columns={columns}
      data={formatData(payIns)}
      options={{
        filtering: true,
        search: false,
        actionsColumnIndex: -1,
        searchFieldStyle: {
          width: 355
        }
      }}
      actions={[
        {
          icon: () => <Icon name='arrowForward' />,
          tooltip: 'Edit Pay-In',
          onClick: (event, rowData) => setSelectedPayIn(rowData)
        }
      ]}
      components={{
        Toolbar: props => (
          <div />
        )
      }}
    />
  )
}

export default PayInList
