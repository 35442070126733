import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'

import GridItem from '../common/GridItem'
import { PaymentGrid, PercentageField, GoldRateField } from '../common/Payments'
import TextField from '../common/TextField'
import { calculatePayIn } from '../common/Payments/calculate'
import { convertEuroCentToEuro, convertEuroToEuroCent, convertMicroMkiToMKI, castToNumber } from '../../utils/convert'

const PayInCalculator = ({ defaultEuroCent, defaultFee = 2, defaultExchangeRate = 50, onChange }) => {
  const [euroCent, setEuroCent] = useState(defaultEuroCent)
  const [fee, setFee] = useState(defaultFee)
  const [exchangeRate, setExchangeRate] = useState(defaultExchangeRate)
  const [microMkiTotal, setMicroMkiTotal] = useState(0)
  const [microMkiUser, setMicroMkiUser] = useState(0)
  const [microMkiFee, setMicroMkiFee] = useState(0)

  useEffect(() => {
    setExchangeRate(defaultExchangeRate)
  }, [defaultExchangeRate, setExchangeRate])

  useEffect(() => {
    setFee(defaultFee)
  }, [defaultFee, setFee])

  useEffect(() => {
    const { microMkiTotal, microMkiUser, microMkiFee } = calculatePayIn({ exchangeRate, fee, euroCent })
    setMicroMkiUser(microMkiUser)
    setMicroMkiFee(microMkiFee)
    setMicroMkiTotal(microMkiTotal)
  }, [euroCent, fee, exchangeRate, onChange])

  useEffect(() => {
    onChange({
      microMki: castToNumber(microMkiTotal),
      euroCent: castToNumber(euroCent),
      fee: castToNumber(fee),
      exchangeRate: castToNumber(exchangeRate)
    })
  }, [microMkiTotal, euroCent, fee, exchangeRate, onChange])

  const changeState = (setState) => async (event) => {
    setState(event.target.value)
  }

  return (
    <PaymentGrid>
      <GridItem>
        <TextField
          placeholder='0'
          value={convertEuroCentToEuro(euroCent)}
          onChange={event => setEuroCent(convertEuroToEuroCent(event.target.value))}
          label='Transfered amount in EUR'
          type='number'
          InputProps={{ inputProps: { min: 0, step: 1 } }}
        />
      </GridItem>
      <GridItem>
        <GoldRateField value={exchangeRate} onChange={changeState(setExchangeRate)} />
      </GridItem>
      <GridItem>
        <PercentageField value={fee} onChange={changeState(setFee)} />
      </GridItem>
      <GridItem container justify='space-between'>
        <Typography display='inline'>Gold payment to user depot: </Typography>
        <Typography display='inline'>{convertMicroMkiToMKI(microMkiUser).toFixed(3)} g</Typography>
      </GridItem>
      <GridItem container justify='space-between'>
        <Typography display='inline'>Gold payment to fee depot: </Typography>
        <Typography display='inline'>{convertMicroMkiToMKI(microMkiFee).toFixed(3)} g</Typography>
      </GridItem>
    </PaymentGrid>
  )
}

export default PayInCalculator
