import styled, { css } from 'styled-components'

const defaultSpace = 20

const Spacer = styled.div`
  ${props => props.height && css`
    height:${props.height}px;
  `}
  ${props => props.width && css`
    width: ${props.width}px;
  `}
  ${props => props.flex && css`
    flex: ${!Number.isInteger(props.flex) ? 1 : props.flex};
  `}
  ${props => !(props.height && props.width) && css`
    height: ${defaultSpace}px;
    width: ${defaultSpace}px;
  `}
`

export default Spacer
