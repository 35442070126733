import React from 'react'
import SearchField from '../common/TextField/SearchField'
import { Paper } from '@material-ui/core'
import { styled, withTheme } from '@material-ui/core/styles'

const SEARCH_FIELD_ADMINS_LABEL = 'Search for Admins'

const AdminSearchField = ({ onSubmit, defaultValue = '' }) => (
  <Paper style={{ height: 56, padding: '5px' }} elevation={3}>
    <SearchField
      label={SEARCH_FIELD_ADMINS_LABEL}
      variant='standard'
      style={{ width: '99.5%' }}
      onSubmit={onSubmit}
      defaultValue={defaultValue}
    />
  </Paper>
)

export default styled(withTheme(AdminSearchField))(props => ({
  background: props.theme.palette.primary.gradient
}))
