import React, { useState, useCallback } from 'react'
import OtpInput from '../Tokens/OtpInput'
import useFetch from 'use-http'
import { useSession } from '../common/Api/Session'

const LoginOtp = ({ onClose }) => {
  const [error, setError] = useState()

  const { put } = useFetch('/admins/session/otp')
  const { startSession } = useSession()

  const handleOtpAuthorization = useCallback(async (otp) => {
    const { error, token } = await put({ otp })
    await setError(error)
    if (token) {
      await startSession({ token })
    }
  }, [startSession, put])

  return (<OtpInput onSubmit={handleOtpAuthorization} error={error} onClose={onClose} />)
}

export default LoginOtp
